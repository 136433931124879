.swiper-calendar {
  max-width: 100%;
  padding: 30px 0px;
  margin-top: 20px;
  background-color: $bg_light;
  min-height: 368px;
  &__date {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 22px;
    padding-left: 24px;
  }
  .day {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    button {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      background-color: transparent;
      border: none;
      &.--active {
        background-color: $text-primary1;
        color: white;
      }
      &:disabled {
        cursor: unset;
        color: $text-primary4;
      }
    }
    .absolute-month {
      position: absolute;
      top: 8px;
      left: 8px;
      &.disabled {
        color: $text-primary4;
      }
    }
    .absolute-today {
      font-size: 1.1rem;
      white-space: nowrap;
      position: absolute;
      bottom: 0px;
    }
  }
  .swiper-container {
    max-width: 100%;
    width: 100%;
  }
  .swiper-slide {
    padding: 0;
  }
  .week-header {
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    padding: 0 24px;
    > div {
      display: flex;
      justify-content: center;
      font-size: 1.4rem;
      margin-top: 20px;
    }
  }
  .days-container {
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    padding: 0 24px;
    background-color: $bg_light;
    padding-bottom: 30px;
  }
  .col-start-1 {
    grid-column-start: 1;
  }
  .col-start-2 {
    grid-column-start: 2;
  }
  .col-start-3 {
    grid-column-start: 3;
  }
  .col-start-4 {
    grid-column-start: 4;
  }
  .col-start-5 {
    grid-column-start: 5;
  }
  .col-start-6 {
    grid-column-start: 6;
  }
  .col-start-7 {
    grid-column-start: 7;
  }
}
