.building-detail {
  .building-info {
    &__main {
      padding: 20px 24px 30px 24px;
      .main-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;
      }
      .main-title {
        font-size: 2rem;
        font-weight: 600;
        line-height: 32px;
      }
      .description {
        font-size: 1.3rem;
        margin-top: 5px;
        color: $text-primary3;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 20px;
      }
    }
    &__building-info {
      padding: 30px 24px;
      h2 {
        font-size: 1.6rem;
        font-weight: 600;
        margin-bottom: 20px;
      }
      .description {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.56px;
        text-align: justify;
        margin-bottom: 20px;
      }
      .info-title {
        font-size: 1.4rem;
        font-weight: 600;
        margin-bottom: 20px;
      }
      .info-line {
        display: flex;
        align-items: center;
        margin-top: 16px;
        font-size: 1.4rem;
        span {
          display: block;
          width: 93px;
          color: $text-primary3;
        }
      }
      .address-area {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .address {
          display: flex;
          flex-direction: column;
          gap: 2px;
          p {
            font-size: 1.4rem;
            color: $text-primary1;
            line-height: 20px;
          }
        }
        button {
          text-decoration: underline;
          font-size: 12px;
          color: $text-primary3;
          border: none;
          background-color: transparent;
        }
      }
      .map-container {
        width: 100%;
        max-width: 100%;
        height: 200px;
        max-height: 272px;
        margin-top: 12px;
      }
    }
  }
}
