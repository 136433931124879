// 공간상품 리스트
.product-list {
  $imgHeight: 400px;
  .product-slider-container {
    width: 100%;
    height: $imgHeight;
    overflow: hidden;
    position: relative;
  }
  .product-slider-box-wrap {
    position: absolute;
    display: flex;
    align-items: center;
  }
  .product-slider-box-title {
    font-size: 2.4rem;
    color: $white;
    font-weight: 700;
    line-height: 3.4rem;
    letter-spacing: -4%;
    z-index: 2;
    white-space: pre;
  }
  .product-slider-box-subtitle {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2rem;
    color: $white;
    padding-top: 38px;
    position: relative;
    white-space: pre;
    &::before {
      content: "";
      position: absolute;
      width: 23px;
      height: 2px;
      background-color: $white;
      top: 18px;
    }
  }
  .indicator {
    position: absolute;
    left: 0;
    bottom: 20px;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $line_dark;
    margin-right: 6px;
    &:last-child {
      margin: 0;
    }
  }
  article {
    padding: 30px 24px 50px 24px;
    h1 {
      font-size: 2.4rem;
      font-weight: 700;
      line-height: 3.4rem;
      margin-bottom: 40px;
    }
    section {
      margin-bottom: 60px;
      &:last-child {
        margin-bottom: 0;
      }
      .title-area {
        margin-bottom: 30px;
        h2 {
          font-size: 1.8rem;
          position: relative;
          width: fit-content;
          &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: $text-primary1;
            bottom: -2px;
            left: 0;
            letter-spacing: -0.04rem;
          }
          margin-bottom: 12px;
        }
        p {
          color: $text-primary3;
          font-size: 1.4rem;
        }
      }
      .product-box-wrap {
        margin-bottom: 40px;
        &:last-child {
          margin-bottom: 0;
        }
        .product-box-img {
          width: 100%;
          height: 300px;
          max-height: 300px;
          background-color: $bg_dark;
          padding: 20px;
          background-size: cover;
          background-position: center;
          position: relative;
        }
        .product-box-info {
          padding: 10px 0;
          h3 {
            font-size: 1.5rem;
            line-height: 22px;
            font-weight: 600;
            letter-spacing: -0.04rem;
            margin-bottom: 2px;
          }
          p {
            color: $text-primary3;
            font-size: 1.2rem;
          }
          p.product-box-desc {
            font-size: 1.4rem;
          }
        }
        .product-box-cost-wrap {
          display: flex;
          align-items: top;
          .product-box-cost {
            margin-right: 20px;

            .product-box-cost-main {
              line-height: 22px;
              position: relative;
              color: $text-primary1;
              span {
                font-size: 1.4rem;
              }
              b {
                font-size: 1.5rem;
              }
              &.product-box-cost-line::after {
                content: "";
                width: 1px;
                height: 100%;
                background-color: $line_dark;
                position: absolute;
                right: -10px;
              }
            }
            .product-box-cost-desc {
              font-size: 1.2rem;
              color: $text-primary3;
              text-decoration: line-through;
            }
          }
        }
      }
    }
  }
}

// 공간상품 상세
.product-detail {
  .product-info {
    &__main {
      padding: 20px 24px 30px 24px;
      .main-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;
      }
      .main-title {
        font-size: 2.2rem;
        font-weight: 600;
        line-height: 26px;
        font-family: "Apple SD Gothic Neo";
      }
      .description {
        font-size: 1.3rem;
        margin-top: 5px;
        color: $text-primary3;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 20px;
      }
      .price-wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 10px;
        margin-bottom: 20px;
        .left-price,
        .right-price {
          &__index {
            font-size: 12px;
            font-weight: 400;
            color: $text-primary3;
          }
          &__price {
            margin-top: 2px;
            p {
              font-size: 1.8rem;
              font-family: "Roboto";
              font-weight: 700;
              line-height: 1.4;
            }
            span {
              font-size: 1.4rem;
              margin-bottom: 2px;
              font-weight: 400;
            }
            span.sub {
              font-size: 1.2rem;
              margin-bottom: 2px;
              font-weight: 400;
              color: $text-primary3;
            }
          }
        }
        .right-price {
          padding-left: 20px;
        }
      }
      .additional-info {
        border-top: 1px solid $text-primary1;
        border-bottom: 1px solid $text-primary1;
        padding: 20px 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 20px;
        .info-wrap {
          &__index {
            font-size: 12px;
            font-weight: 400;
            color: $text-primary3;
            line-height: 16px;
          }
          &__value {
            margin-top: 2px;
            p {
              font-size: 1.4rem;
              font-weight: 600;
              line-height: 20px;
            }
          }
          &.right {
            padding-left: 20px;
          }
        }
      }
      .building-area-wrap {
        padding-top: 30px;
        h3 {
          font-size: 1.5rem;
          font-weight: 600;
        }
        &__line {
          display: grid;
          grid-template-columns: 1fr 1fr;
          white-space: nowrap;
          p {
            font-size: 1.4rem;
            line-height: 20px;
            &.right {
              padding-left: 20px;
            }
          }
        }
        p.label {
          font-size: 1.4rem;
          line-height: 20px;
          color: $text-primary3;
        }
        button.more {
          width: 100%;
          height: 32px;
          font-size: 1.4rem;
          background-color: transparent;
          border: 1px solid $line_light;
          border-radius: 0;
          margin-top: 16px;
        }
      }
    }
    &__product-info {
      padding: 30px 24px;
      h2 {
        font-size: 1.6rem;
        font-weight: 600;
        margin-bottom: 20px;
      }
      .description {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.56px;
        text-align: justify;
        white-space: pre-wrap;
      }
      .info-title {
        font-size: 1.4rem;
        font-weight: 600;
        margin-bottom: 20px;
      }
      .info-line {
        display: flex;
        align-items: center;
        margin-top: 16px;
        font-size: 1.4rem;
        span {
          display: block;
          width: 93px;
          color: $text-primary3;
        }
      }
      .address-area {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .address {
          display: flex;
          flex-direction: column;
          gap: 2px;
          p {
            font-size: 1.4rem;
            color: $text-primary3;
            line-height: 20px;
          }
        }
        button {
          text-decoration: underline;
          font-size: 12px;
          color: $text-primary3;
          border: none;
          background-color: transparent;
        }
      }
      .map-container {
        width: 100%;
        max-width: 100%;
        height: 200px;
        max-height: 272px;
        margin-top: 12px;
      }
    }
    &__building-info {
      padding: 30px 24px;
      h2 {
        font-size: 1.6rem;
        font-weight: 600;
        margin-bottom: 20px;
      }
      .building-title {
        font-size: 1.2rem;
        padding-left: 18px;
        font-weight: 600;
        position: relative;
        &::before {
          content: "";
          width: 14px;
          height: 14px;
          background-image: url("../../images/icons_building.svg");
          background-repeat: no-repeat;
          background-size: contain;
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: 1px;
          transform: translateY(-50%);
        }
      }
    }
  }
}

.product-inquery {
  .product-title {
    padding: 30px 24px 0px 24px;
    h1 {
      font-size: 2.4rem;
      line-height: 34px;
      letter-spacing: -0.04em;
      padding-bottom: 50px;
      &.message {
        font-size: 2.2rem;
        font-weight: 600;
        padding-bottom: 10px;
      }
    }
  }
  .product-main {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 30px;
  }
  .product-picture {
    padding: 30px 0px;
    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 24px;
      &-left {
        display: flex;
        align-items: center;
        p {
          font-size: 1.6rem;
          color: $text-primary1;
          font-weight: 600;
          margin-right: 10px;
        }
        span {
          font-size: 1.4rem;
          @include font("roboto");
          color: $text-primary3;
          letter-spacing: unset;
        }
      }
      &-right {
        font-size: 1.2rem;
        color: $text-primary3;
      }
    }
    &__img {
      margin-top: 20px;
      overflow-x: auto;
      padding-left: 24px;
      display: flex;
      align-items: center;
      @include scroll-hidden;
      padding-right: 14px;
      .image-wrap {
        position: relative;
        .white-close-btn {
          position: absolute;
          width: 18px;
          height: 18px;
          top: 6px;
          left: 54px;
          background-size: 20px;
        }
      }
      img {
        width: 78px;
        height: 78px;
        border: 1px solid $line-dark;
        position: relative;
        border-radius: 4px;
        display: block;
        margin-right: 10px;
      }
      &-noimg {
        width: 78px;
        height: 78px;
        min-width: 78px;
        min-height: 78px;
        border: 1px solid $line-dark;
        border-radius: 4px;
        background-color: $bg_dark;
        position: relative;
        margin-right: 10px;
        cursor: pointer;
        &::after {
          content: "";
          width: 24px;
          height: 24px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          position: absolute;
          background-image: url("../../images/icons_add.svg");
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }
  }
  .product-contact {
    padding: 30px 24px;
    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      &-left {
        display: flex;
        align-items: center;
        p {
          font-size: 1.6rem;
          color: $text-primary1;
          font-weight: 600;
          margin-right: 10px;
        }
      }
    }
  }
  &__complete {
    .product-title {
      h1 {
        font-weight: 600;
        padding-bottom: 10px;
      }
    }
    .product-contents {
      padding: 0 24px;
      p {
        font-size: 14px;
        line-height: 20px;
        color: $text-primary3;
      }
    }
  }
}

h2.title-underline {
  font-size: 1.2rem;
  position: relative;
  width: fit-content;
  margin-bottom: 2px;
  margin-right: 4px;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: $text-primary1;
    bottom: -2px;
    left: 0;
    letter-spacing: -0.04rem;
  }
}
.top-line-container {
  width: 100%;
  position: relative;
  padding: 12px;
  border: 1px solid $line_light;
  border-top: 1px solid $text-primary1;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  align-items: center;
  &__wrap {
    display: flex;
    align-items: flex-start;
    width: 100%;
  }
  img {
    width: 100px;
    height: 80px;
    margin-right: 12px;
    display: block;
    object-fit: cover;
  }
  &__info {
    overflow: hidden;
    h3 {
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 22px;
      width: 100%;
      max-width: 100%;
      padding-right: 30px;
      @include ellipsis;
    }

    p {
      font-size: 1.2rem;
      position: relative;
      margin-top: 6px;

      &.ic-users {
        padding-left: 16px;
        &::before {
          content: "";
          position: absolute;
          width: 12px;
          height: 12px;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          background: url("../../images/icons_users_g.svg") no-repeat;
        }
      }
    }
  }
  &__price {
    display: flex;
    align-items: center;
    padding-top: 14px;
    .price {
      display: flex;
      align-items: center;
      white-space: nowrap;
      h3 {
        font-size: 16px;
        font-weight: 700;
      }
      span {
        font-size: 14px;
        margin-left: 2px;
      }
      span:last-child {
        font-size: 12px;
        color: $text-primary3;
      }
    }
  }

  button {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    background-image: url("../../images/icons_circle_right.svg");
    border: none;
    background-color: transparent;
  }
}

.navigation-map {
  width: 100%;
  height: 40px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: $bg_light;
  margin-top: 12px;
  ul {
    width: 100%;
    display: flex;
    list-style: none;
    height: 100%;
    li {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      font-size: 12px;
      .map-icon {
        width: 20px;
        height: 20px;
        background-position: left;
        background-size: 20px 20px;
        background-repeat: no-repeat;
        margin-right: 4px;
        &.ic-naver {
          background-image: url("../../images/map_navermap.svg");
        }
        &.ic-kakao {
          background-image: url("../../images/map_kakakomap.svg");
        }
        &.ic-t {
          background-image: url("../../images/map_tmap.svg");
        }
        &.ic-google {
          background-image: url("../../images/map_googlemap.svg");
        }
      }
      &:not(:first-child):before {
        position: absolute;
        content: "|";
        left: 0;
        font-size: 14px;
        color: $line_light;
      }
    }
  }
}
