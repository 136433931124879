button {
  font-family: "Apple SD Gothic Neo", sans-serif;
  letter-spacing: -0.04em;
  color: $text-primary1;
}
.base-floating-btn-wrap {
  position: sticky;
  bottom: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 24px;
  padding-right: 24px;
  background-color: white;
  z-index: 100;
}

.login-btn {
  height: 54px;
  color: $white;
  width: 100%;
  border: none;
  background-color: $text-primary1;
  font-size: 1.5rem;
  border-radius: 4px;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  @include disabled-btn;
}
.clear-btn {
  width: 40px;
  height: 40px;
  background: url("../../images/icons_clear.svg") no-repeat center;
  border: none;
}
.delete-btn {
  width: 40px;
  height: 40px;
  background: url("../../images/icons_delete.svg") no-repeat center;
  border: none;
}
.search-btn {
  width: 40px;
  height: 40px;
  background: url("../../images/icons_medium_search.svg") no-repeat center;
  border: none;
  background-color: transparent;
}
.check-green-only {
  width: 40px;
  height: 40px;
  background: url("../../images/icons_check_green_only.svg") no-repeat center;
  border: none;
  cursor: unset;
}
.check-green-btn {
  width: 40px;
  height: 40px;
  background: url("../../images/icons_check_green.svg") no-repeat center;
  border: none;
  cursor: unset;
}
.none-btn {
  width: 40px;
  height: 40px;
  visibility: hidden;
}

.base-btn {
  width: 100%;
  height: 54px;
  white-space: nowrap;
  font-size: 1.5rem;
  font-weight: 600;
  background-color: $text-primary1;
  border: 1px solid $text-primary1;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  color: $white;
  line-height: 16px;
  z-index: 99; // harry 수정 > v.1.19 > 사파리에서 화면 밀림 현상으로 예약하기 버튼이 반쯤 가리게 나옴 > QA-2275 내용
  cursor: pointer;
  @include disabled-btn;
  &.color-white {
    background-color: $white;
    border: 1px solid $line_dark;
    color: $text-primary1;
    &:disabled {
      background-color: $bg_light;
      border: 1px solid $line_dark;
      color: $text-primary4;
    }
  }
  &.color-purple {
    background-color: #5147b8;
    border: 1px solid $line_dark;
  }
  &.size-small {
    height: 40px;
  }
  &:disabled {
    background-color: $line_light;
    color: $white;
    border: none;
    &.--loading {
      span {
        display: none;
      }
    }
  }
}
.underline-btn {
  position: absolute;
  font-size: 1.4rem;
  bottom: 0;
  right: 0;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  color: $text-primary3;
}
.cta-btn {
  width: 166px;
  min-width: 120px;
  white-space: nowrap;
  font-size: 1.5rem;
  font-weight: 600;
  background-color: $text-primary1;
  border: 1px solid $text-primary1;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 54px;
  padding: 0 20px;
  color: $white;
  line-height: 16px;
  @include disabled-btn;
}
.info-btn {
  width: 24px;
  min-width: 24px;
  height: 24px;
  background: url("../../images/icons_info.svg") no-repeat center;
  border: none;
}
.close-btn {
  width: 30px;
  height: 30px;
  background: url("../../images/icons_large_close.svg") no-repeat center;
  border: none;
}
.white-close-btn {
  width: 30px;
  height: 30px;
  background: url("../../images/icons_close_white.svg") no-repeat center;
  border: none;
}
.visible-btn {
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: none;
  background: url("../../images/icons_pw_open.svg") no-repeat center;
  &.--active {
    background: url("../../images/icons_pw_close.svg") no-repeat center;
  }
}
.copy-btn {
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: none;
  background: url("../../images/icons_copy.svg") no-repeat center;
}
.question-btn {
  width: 100%;
  height: 62px;
  border: 1px solid $line_dark;
  border-radius: 4px;
  background-color: transparent;
  font-size: 1.5rem;
  font-weight: 600;
  span {
    padding-left: 40px;
    position: relative;
  }
  &.icon-message span::before {
    content: "";
    position: absolute;
    left: 0;
    width: 30px;
    height: 30px;
    background-position: center;
    background-image: url("../../images/icons_large_dm.svg");
    background-repeat: no-repeat;
    top: 50%;
    transform: translateY(-50%);
  }
  &.icon-call span::before {
    content: "";
    position: absolute;
    left: 0;
    width: 30px;
    height: 30px;
    background-position: center;
    background-image: url("../../images/icons_large_phone.svg");
    background-repeat: no-repeat;
    top: 50%;
    transform: translateY(-50%);
  }
}
.icon-right-btn {
  border: none;
  background-color: transparent;
  font-size: 1.4rem;
  padding-right: 16px;
  white-space: nowrap;
  color: $text-primary1;
  background-image: url("../../images/icons_small_arrow_right.svg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: 14px;
  &.--active::after {
    content: "";
    width: 14px;
    height: 14px;
    right: 0;
  }
  &.ic-chevron-right {
    background-image: url("../../images/icons_small_arrow_right.svg");
    &.--active {
      background-image: none;
      position: relative;
      width: fit-content;
    }
    &.--active::after {
      content: "";
      position: absolute;
      width: 14px;
      height: 14px;
      background-image: url("../../images/icons_small_arrow_right.svg");
      top: 50%;
      right: 0;
      transform: translateY(-50%) rotate(90deg);
    }
    &.icon-gray {
      background-image: url("../../images/icons_small_arrow_right_gray.svg");
    }
  }
  &.ic-download {
    padding-right: 20px;
    background-image: url("../../images/icons_small_download.svg");
    background-size: 14px;
  }
  &.ic-small-copy {
    padding-right: 20px;
    background-image: url("../../images/icons_small_copy.svg");
  }
}
.back-btn {
  width: 30px !important;
  height: 30px;
  background-image: url("../../images/icons_large_back.svg");
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  background-size: contain;
  background-color: transparent;
}
.chevron-down-btn {
  width: fit-content;
  height: auto;
  font-size: 1.6rem;
  line-height: 22px;
  font-weight: 600;
  color: $text-primary1;
  padding-right: 34px;
  border: none;
  background-color: transparent;
  position: relative;
  max-width: 100%;
  &::after {
    content: "";
    width: 24px;
    height: 24px;
    right: 0;
    top: 50%;
    position: absolute;
    background-image: url("../../images/icons_chevron_right.svg");
    background-repeat: no-repeat;
    background-position: right;
    transform: translateY(-50%) rotate(90deg);
  }
  &:disabled {
    padding-right: 0;
    &::after {
      display: none;
    }
  }
  &.--active::after {
    transform: translateY(-50%) rotate(270deg);
  }
}
.right-chevron-btn {
  position: absolute;
  right: 0;
  padding-right: 14px;
  background-image: url("../../images/icons_small_arrow_right.svg");
  background-repeat: no-repeat;
  background-position: right;
  background-color: transparent;
  border: none;
  opacity: 0.5;
}

.header-text-btn {
  width: fit-content;
  white-space: nowrap;
  color: $text-primary1;
  font-weight: 400;
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  &:disabled {
    color: $text-primary3;
    cursor: unset;
  }
}
.desc-comp-btn {
  color: $text-primary1;
  border-radius: 0;
  border: 1px solid $text-primary4;
  width: 48px;
  height: 31px;
  font-size: 1.3rem;
  line-height: 21px;
  background-color: $white;
  position: absolute;
  &:disabled {
    background-color: $bg_dark;
    color: $text-primary4;
  }
  &.--active {
    background-color: $text-primary1;
    border-color: $text-primary1;
    color: $white;
  }
}
.only-text-btn {
  color: $text-primary1;
  width: fit-content;
  white-space: nowrap;
  font-weight: 400;
  background-color: transparent;
  border: none;
  font-size: 1.4rem;
  &.ic-chevron-right {
    padding-right: 14px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 14px;
      height: 14px;
      background-image: url("../../images/icons_small_arrow_right.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }
}
// chip - 기본컬러는 블랙
.base-chip {
  width: fit-content;
  min-width: 61px;
  height: 24px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px 1px 10px;
  white-space: nowrap;
  font-size: 1.2rem;
  color: $white;
  background-color: $text-primary1;
  border-radius: 2px;
  line-height: 16px;
  &.color-gray {
    background-color: $text-primary3;
  }
  &.color-brown {
    background-color: $key1;
  }
  &.color-red {
    background-color: $system_red;
  }
}
.share-btn {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  border: none;
  background: $white url("../../images/icons_share.svg") center no-repeat;
  background-size: contain;
}
.space-chip {
  width: fit-content;
  min-width: 40px;
  font-size: 1.2rem;
  font-weight: 400;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.5px;
  background-color: $text-primary1;
  color: $white;
  border: 1px solid $text-primary1;
  border-radius: 4px;
  &.color-white {
    background-color: $white;
    color: $text-primary1;
  }
}
.show-more-btn {
  border: none;
  text-align: right;
  background-color: transparent;
  position: relative;
  padding-right: 18px;
  font-size: 12px;
  color: $text-primary3;
  &::after {
    content: "";
    position: absolute;
    right: 0px;
    width: 14px;
    height: 14px;
    background-image: url("../../images/icons_chevron_down_g.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  &.--active::after {
    transform: rotate(180deg);
  }
}

.file-upload-btn {
  width: 78px;
  height: 78px;
  border: 1px dashed $line_dark;
  border-radius: 4px;
  position: relative;
  background-color: white;
  &::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    top: 20px;
    width: 24px;
    height: 24px;
    background-position: center center;
    background-image: url("../../images/icons_camera.svg");
    background-size: 30px 30px;
    background-repeat: no-repeat;
  }
  &.gray {
    &::after {
      opacity: 0.2;
    }
  }
}

.file-upload-wrap {
  position: relative;
  img {
    width: 78px;
    height: 78px;
    border-radius: 4px;
    border: 1px solid $line_dark;
    object-fit: cover;
    display: block;
  }
  .file-delete-btn {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 24px;
    height: 24px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: #000;
    border: none;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 24px;
      height: 24px;
      background-position: center center;
      background-image: url("../../images/icons_close_white.svg");
      background-size: 18px 18px;
    }
  }
  .title-area {
    display: none; // 임시
  }
}

.close-preview-btn {
  width: 30px;
  height: 30px;
  background-image: url("../../images/icons_close_white.svg");
  border: none;
  position: absolute;
  left: 24px;
  top: 7px;
  background-color: transparent;
}

.guide-file-download {
  border: 1px solid $line-light;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  height: 36px;
  position: relative;
  background-color: transparent;
  width: 100%;
  font-size: 14px;
  &::after {
    content: "";
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    width: 14px;
    height: 14px;
    background-image: url("../../images/icons_small_download.svg");
    background-repeat: no-repeat;
  }
}

.center-item {
  align-items: center;
  justify-content: center;
}
