.notice-area {
  width: 100%;
  height: 60px;
  min-height: 60px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $line_light;
  cursor: pointer;
  &::after {
    content: "";
    width: 18px;
    height: 18px;
    background: url("../../images/icons_small_arrow_right.svg") center/contain no-repeat;
  }
  span {
    margin-right: 10px;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
  }
  p {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 36px;
    width: 100%;
  }
}
.main-contents {
  padding: 40px 24px;
  flex: 1;
  &__top {
    h2 {
      font-size: 1.8rem;
      font-weight: 600;
    }
    .tab-wrap {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: 16px;
      margin-bottom: 20px;
      .tab {
        width: fit-content;
        height: 36px;
        min-width: 64px;
        padding: 8px 20px;
        display: flex;
        align-items: center;
        border: 1px solid $line_light;
        border-radius: 40px;
        font-size: 1.4rem;
        letter-spacing: -0.6px;
        color: $text-primary1;
        background: $bg_light;
        cursor: pointer;
        &.--active {
          background-color: $text-primary1;
          color: $white;
          border-color: $text-primary1;
        }
      }
    }
  }
}
.space-product {
  ul {
    li {
      list-style: none;
      // margin-top: 20px;
      margin-bottom: 40px;
    }

    .information {
      padding-top: 20px;
      cursor: pointer;
      .inline-wrap {
        position: relative;
      }
      .space-chip {
        display: inline-block;
        min-height: 24px;
        max-height: 24px;
        position: absolute;
        top: 2px;
        height: auto;
        text-align: center;
        margin: 0px;
        span {
          display: block;
          white-space: nowrap;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      h2 {
        display: inline;
        word-break: break-all;
        padding-left: 48px;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px; /* 144.444% */
        letter-spacing: -0.72px;
      }
      p.desc {
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 36px;
        margin-top: 4px;
        margin-bottom: 4px;
        color: $text-primary3;
      }
      p.info {
        padding-left: 16px;
        font-size: 1.4rem;
        position: relative;
        &.ic-place::before {
          content: "";
          position: absolute;
          width: 12px;
          height: 20px;
          left: 0;
          background-image: url("../../images/icons_place_g.svg");
          background-repeat: no-repeat;
          top: 50%;
          transform: translateY(-50%);
        }
        &.ic-size::before {
          content: "";
          position: absolute;
          width: 12px;
          height: 12px;
          left: 0;
          background-image: url("../../images/icons_size_g.svg");
          background-repeat: no-repeat;
          top: 50%;
          transform: translateY(-50%);
        }
        &.ic-users::before {
          content: "";
          position: absolute;
          width: 12px;
          height: 12px;
          left: 0;
          background-image: url("../../images/icons_users_g.svg");
          background-repeat: no-repeat;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .vertical-divider {
        width: 1px;
        height: 20px;
        background-color: $line_dark;
        margin: 0 10px;
      }
    }
    .price-info-container {
      display: flex;
      align-items: center;
      margin-top: 6px;
    }
    .price-info {
      display: flex;
      align-items: flex-end;
      p.price {
        font-size: 22px;
        font-family: "Roboto";
        font-weight: 700;
        margin-right: 2px;
        // &.no-data{
        //   min-width:30px;
        //   text-align: center;
        // }
      }
      p.text {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        letter-spacing: -0.56px;
      }
      .text span {
        font-size: 12px;
        color: $text-primary3;
      }
    }
  }
}

.page-soon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  .icon-soon {
    width: 62px;
    height: 85px;
    background-image: url("../../images/icons_smile.svg");
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: 30px;
  }
  h1 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  p {
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.6px;
  }
}

.no-data {
  height: calc(100% - 77px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  p {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    margin-top: 30px;
  }
}
