.taap-page {
  width: 100%;
  height: 100%;
  padding-left: 139px;
  padding-right: 139px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  .title {
    margin-top: 40px;
    margin-bottom: 60px;
    font-size: 2.2rem;
    line-height: 32px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
  }
  .taap-icon {
    width: 110px;
    height: 110px;
    margin: 0 auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../images/app_taap.svg");
  }
  .taap-desc {
    margin-top: 20px;
    font-size: 1.4rem;
    line-height: 20px;
    margin-bottom: 0px;
  }
  .redirect-area {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    .redirect-desc {
      margin-bottom: 11px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    .redirect-desc-icon {
      width: 20px;
      height: 20px;
      background-image: url("../../images/icons_timer_start.svg");
    }
    .redirect-desc-text {
      margin-left: 4px;
      font-size: 14px;
      line-height: 20px;
      color: $text-primary2;
      letter-spacing: -0.04em;
      white-space: nowrap;
    }
  }
  height: 100dvh;
  padding-top: 140px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 20px;
}
