.page-notice-list{
    padding:20px 24px;
    .list-section__item .title{
        width:100%;
    }
    .notice-title{
        font-weight:600;
        font-size:1.6rem;
        line-height:22px;
        @include ellipsis;
    }
}