// visitor에서 사용하는 아코디언

.base-accordion {
  width: 100%;
  &__title-area {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 37px;
    border-bottom: 2px solid $text-primary1;
    span {
      font-size: 16px;
      font-weight: 600;
    }
    > button {
      width: 24px;
      height: 24px;
      background: url("../../images/icons_arrow_down.svg") no-repeat center;
      border: none;
    }
  }

  &.--active {
    .base-accordion__title-area {
      button {
        transform: rotate(180deg);
      }
    }
  }
  &__contents {
    .desc {
      font-size: 1.4rem;
      line-height: 20px;
      color: $text-primary3;
      letter-spacing: -0.04em;
      margin-top: 20px;
      word-break: break-all;
      white-space: pre-line;
    }
    section.location {
      padding-top: 30px;
      padding-bottom: 20px;
      &:first-child {
        padding-top: 20px;
      }
    }
    ul {
      // padding-top:20px;
      li {
        list-style: none;
        font-size: 1.4rem;
        line-height: 20px;
        letter-spacing: -0.04em;
        color: $text-primary1;
        position: relative;
        padding-left: 12px;
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        letter-spacing: -0.04px;
        &:last-child {
          margin-bottom: 0;
        }
        &:before {
          content: "";
          width: 3px;
          height: 3px;
          border-radius: 8px;
          background-color: $text_primary1;
          position: absolute;
          left: 0;
          top: 9px;
        }
      }
    }
  }
}

.new-accordion {
  width: 100%;
  &__title-area {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 37px;
    border-bottom: 2px solid $text-primary1;
    span {
      font-size: 16px;
      font-weight: 600;
    }
    > button {
      width: 24px;
      height: 24px;
      background: url("../../images/icons_arrow_down.svg") no-repeat center;
      border: none;
    }
  }

  &.--active {
    .base-accordion__title-area {
      button {
        transform: rotate(180deg);
      }
    }
  }
}
