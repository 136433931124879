.components-page {
  padding: 30px;
  &__menu {
    display: flex;
    margin-bottom: 20px;
    &-chip {
      font-size: 2rem;
      margin-right: 20px;
      position: relative;
      &.--active {
        font-weight: bold;

        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          background-color: #000;
          bottom: -4px;
          left: 0;
        }
      }
    }
  }
  &__box {
    margin-bottom: 40px;
    padding: 20px;
    border: 1px solid #000;

    .label {
      font-size: 1.5rem;
      display: flex;
      align-items: center;
      color: #999;
      font-weight: 400;
      position: relative;
      left: -20px;
      top: -20px;
      background-color: black;
      width: fit-content;
      padding: 10px;
      color: white;
    }
  }
  &__grid-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 10px;
  }
  .size-14 {
    .icon-box {
      width: 14px;
      height: 14px;
    }
  }
  .size-24 {
    .icon-box {
      width: 24px;
      height: 24px;
    }
  }
  .size-30 {
    .icon-box {
      width: 30px;
      height: 30px;
    }
  }
  .size-62 {
    .icon-box {
      width: 62px;
      height: 83px;
    }
  }
  .size-101 {
    .icon-box {
      width: 101px;
      height: 71px;
    }
  }
  .icon-box {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    &.ic-icons-add {
      background-image: url("../../images/icons_add.svg");
    }
    &.ic-icons-arrow-down {
      background-image: url("../../images/icons_arrow_down.svg");
    }
    &.ic-icons-arrow-up {
      background-image: url("../../images/icons_arrow_up.svg");
    }
    &.ic-icons-back {
      background-image: url("../../images/icons_back.svg");
    }
    &.ic-icons-check-green-only {
      background-image: url("../../images/icons_check_green_only.svg");
    }
    &.ic-icons-check-green {
      background-image: url("../../images/icons_check_green.svg");
    }
    &.ic-icons-check-green {
      background-image: url("../../images/icons_check_green.svg");
    }
    &.ic-icons-check {
      background-image: url("../../images/icons_check.svg");
    }
    &.ic-icons-checkbox-checked {
      background-image: url("../../images/icons_checkbox_checked.svg");
    }
    &.ic-icons-checkbox-checked {
      background-image: url("../../images/icons_checkbox_checked.svg");
    }
    &.ic-icons-checkbox {
      background-image: url("../../images/icons_checkbox.svg");
    }
    &.ic-icons-chevron-down {
      background-image: url("../../images/icons_chevron_down.svg");
    }
    &.ic-icons-chevron-right {
      background-image: url("../../images/icons_chevron_right.svg");
    }
    &.ic-icons-chevron-up {
      background-image: url("../../images/icons_chevron_up.svg");
    }
    &.ic-icons-clear {
      background-image: url("../../images/icons_clear.svg");
    }
    &.ic-icons-complete {
      background-image: url("../../images/icons_complete.svg");
    }
    &.ic-icons-copy {
      background-image: url("../../images/icons_copy.svg");
    }
    &.ic-icons-desk {
      background-image: url("../../images/icons_desk.svg");
    }
    &.ic-icons-error-underline {
      background-image: url("../../images/icons_error_underline.svg");
    }
    &.ic-icons-error {
      background-image: url("../../images/icons_error.svg");
    }
    &.ic-icons-info {
      background-image: url("../../images/icons_info.svg");
    }
    &.ic-icons-medium-my {
      background-image: url("../../images/icons_medium_my.svg");
    }
    &.ic-icons-medium-search {
      background-image: url("../../images/icons_medium_search.svg");
    }
    &.ic-icons-meetingroom {
      background-image: url("../../images/icons_meetingroom.svg");
    }
    &.ic-icons-others-park1 {
      background-image: url("../../images/icons_others_park1.svg");
    }
    &.ic-icons-others-park2 {
      background-image: url("../../images/icons_others_park2.svg");
    }
    &.ic-icons-pw-close {
      background-image: url("../../images/icons_pw_close.svg");
    }
    &.ic-icons-pw-open {
      background-image: url("../../images/icons_pw_open.svg");
    }
    &.ic-icons-refreshroom {
      background-image: url("../../images/icons_refreshroom.svg");
    }
    &.ic-icons-small-arrow-right {
      background-image: url("../../images/icons_small_arrow_right.svg");
    }
    &.ic-icons-small-download {
      background-image: url("../../images/icons_small_download.svg");
    }
    &.ic-small-arrow-right-gray {
      background-image: url("../../images/icons_small_arrow_right_gray.svg");
    }
    &.ic-icons-small-info {
      background-image: url("../../images/icons_small_info.svg");
    }
    &.ic-icons-small-my {
      background-image: url("../../images/icons_small_my.svg");
    }
    &.ic-icons-underline-none {
      background-image: url("../../images/icons_underline_none.svg");
    }
    &.ic-icons-underline-question {
      background-image: url("../../images/icons_underline_question.svg");
    }
    &.ic-icons-underline-setting {
      background-image: url("../../images/icons_underline_setting.svg");
    }
    &.ic-icons-underline-wifi {
      background-image: url("../../images/icons_underline_wifi.svg");
    }
    &.ic-icons-large-airconditoner {
      background-image: url("../../images/icons_large_airconditoner.svg");
    }
    &.ic-icons-large-bell {
      background-image: url("../../images/icons_large_bell.svg");
    }
    &.ic-icons-large-call {
      background-image: url("../../images/icons_large_call.svg");
    }
    &.ic-icons-large-clean {
      background-image: url("../../images/icons_large_clean.svg");
    }
    &.ic-icons-large-close {
      background-image: url("../../images/icons_large_close.svg");
    }
    &.ic-icons-large-dm {
      background-image: url("../../images/icons_large_dm.svg");
    }
    &.ic-icons-large-edit {
      background-image: url("../../images/icons_large_edit.svg");
    }
    &.ic-icons-large-electric {
      background-image: url("../../images/icons_large_electric.svg");
    }
    &.ic-icons-large-electro {
      background-image: url("../../images/icons_large_electro.svg");
    }
    &.ic-icons-large-etc {
      background-image: url("../../images/icons_large_etc.svg");
    }
    &.ic-icons-large-gas {
      background-image: url("../../images/icons_large_gas.svg");
    }
    &.ic-icons-large-general-clean {
      background-image: url("../../images/icons_large_general_clean.svg");
    }
    &.ic-icons-large-general-gas {
      background-image: url("../../images/icons_large_general_gas.svg");
    }
    &.ic-icons-large-general-water {
      background-image: url("../../images/icons_large_general_water.svg");
    }
    &.ic-icons-large-infodesk {
      background-image: url("../../images/icons_large_infodesk.svg");
    }
    &.ic-icons-large-launge {
      background-image: url("../../images/icons_large_launge.svg");
    }
    &.ic-icons-large-lock {
      background-image: url("../../images/icons_large_lock.svg");
    }
    &.ic-icons-large-mail {
      background-image: url("../../images/icons_large_mail.svg");
    }
    &.ic-icons-large-noimg-building {
      background-image: url("../../images/icons_large_noimg_building.svg");
    }
    &.ic-icons-large-noimg {
      background-image: url("../../images/icons_large_noimg.svg");
    }
    &.ic-icons-large-parking {
      background-image: url("../../images/icons_large_parking.svg");
    }
    &.ic-icons-large-phone {
      background-image: url("../../images/icons_large_phone.svg");
    }
    &.ic-icons-large-print {
      background-image: url("../../images/icons_large_print.svg");
    }
    &.ic-icons-large-product {
      background-image: url("../../images/icons_large_product.svg");
    }
    &.ic-icons-large-security {
      background-image: url("../../images/icons_large_security.svg");
    }
    &.ic-icons-large-snack {
      background-image: url("../../images/icons_large_snack.svg");
    }
    &.ic-icons-large-water {
      background-image: url("../../images/icons_large_water.svg");
    }
    &.ic-icons-large-wc {
      background-image: url("../../images/icons_large_wc.svg");
    }
    &.ic-icons-large-wifi {
      background-image: url("../../images/icons_large_wifi.svg");
    }
    &.ic-icons-small-copy {
      background-image: url("../../images/icons_small_copy.svg");
    }
    &.ic-icons-close-white {
      background-image: url("../../images/icons_close_white.svg");
      background-color: #000;
    }
    &.ic-icons-alert-white {
      background-image: url("../../images/icons_alert_white.svg");
      background-color: #000;
    }
  }
}
