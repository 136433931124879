.react-select-container {
  width: 100%;
  .react-select__control {
    border: none;
    border-bottom: 1px solid $line_dark;
    border-radius: 0;
    min-height: 54px;
    &:hover {
      border-color: $line_dark;
    }
    &:active {
      border-color: $line_dark;
    }
    &:focus {
      border-color: $line_dark;
    }
  }
}
.base-select {
  position: relative;
  width: 100%;
  &.text-left {
    .react-select__single-value {
      font-size: 1.6rem;
      text-align: left;
      margin-left: 0;
    }
  }
  &__prefix {
    position: absolute;
    top: 17px;
    left: 0;
    z-index: 1;
    font-size: 1.6rem;
    color: $text-primary4;
  }
  .react-select {
    &__value-container {
      padding: 0;
      padding-right: 6px;
    }
    &__single-value {
      font-size: 1.6rem;
      text-align: right;
    }
    &__indicator-separator {
      display: none;
    }
    &__placeholder {
      font-size: 1.6rem;
      color: $text-primary1;
      white-space: nowrap;
      border-color: #000;
      box-shadow: none;
      text-align: right;
    }
    &__control--is-focused {
      // border-color: #000;
      box-shadow: unset;
    }
    &__control--menu-is-open {
      .react-select__indicator {
        background-image: url("../../images/icons_chevron_up.svg");
      }
    }
    &__menu {
      z-index: $select-z-in;
      width: fit-content;
      right: 0;
      box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
    }
    &__menu-list {
      padding-top: 10px;
      @include scroll-hidden;

      .react-select__option {
        font-size: 1.5rem;
      }
    }
    &__option {
      background-color: $white;
      padding-top: 14px;
      padding-bottom: 14px;
      padding-left: 40px;
      padding-right: 50px;
    }
    &__option--is-focused {
      background-color: white;
    }
    &__option--is-selected {
      // background-color: $line_dark;
      color: $text-primary1;
      position: relative;
      &:active {
        background-color: $line_dark !important;
      }
      &::after {
        content: "";
        position: absolute;
        right: 22px;
        top: 50%;
        transform: translateY(-60%);
        width: 24px;
        height: 24px;
        background-image: url("../../images/icons_check.svg");
        background-position: center;
        background-repeat: no-repeat;
      }
    }
    &__option:not(.react-select__option--is-selected) {
      &:hover,
      &:active {
        background-color: $line_gray;
      }
    }
    &__indicator {
      color: #000;
      background-image: url("../../images/icons_chevron_down.svg");
      background-repeat: no-repeat;
      background-position: center;
      svg {
        display: none;
        width: 16px;
        height: 16px;
      }
    }
  }
}
.validation-text {
  font-size: 1.4rem;
  padding-top: 8px;
  color: $system-red;
}
