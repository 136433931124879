// taap 웹뷰로 court 접근 시 css mobile 오버라이딩

// 모바일 제어
html body.open-taap {
  .d-none-mo {
    display: none;
  }
  .base-title-wrap {
    display: none;
  }
  .base-title {
    display: none;
  }
  .base-btn-container .base-btn-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .bread-crumbs {
    display: none;
  }
  .empty-bread-crumbs {
    display: none;
  }
  footer.footer-pc {
    display: none;
  }
  .header-pc {
    display: none;
  }
  .taap-page {
    height: 100vh !important;
    padding-top: 140px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 20px;
    .taap-desc {
      margin-bottom: 0px;
    }
  }
  .user-invite-add__invite-btn-wrap {
    display: none;
  }
  .user-invite-title {
    display: none;
  }
}

// 데스크탑 제어
html body.open-taap {
  overflow-y: unset;
  height: 100%;
  background-color: $white;
  .with-backward-floating {
    min-height: $with-backward-floating;
  }
  .d-none-pc {
    display: visible;
  }
  .base-layout-container {
    overflow: auto;
    max-width: 100vw;
  }
  .base-layout {
    .base-layout-contents {
      padding-top: inherit;
      padding-left: inherit;
      padding-right: inherit;
      padding-bottom: inherit;
      min-height: unset;
      position: relative;
      border: unset;
    }
  }
  .base-section-layout {
    padding: 0px 24px;
    &:last-child {
      .base-section-layout__wrap {
        &:last-child {
          padding-bottom: unset;
        }
      }
    }
  }
  .contents-detail {
    padding: 30px 24px;
  }
  .contents-empty {
    position: relative;
    min-height: $only_backward_header; //화면마다 다를경우 새로 정의
    top: unset;
    left: unset;
    transform: unset;
  }
  .base-floating-btn-wrap {
    display: block;
  }
  footer.footer-mo {
    display: block;
  }
  .header {
    display: flex;
  }
  .select-header-wrap {
    .header {
      display: flex;
    }
  }
  .backward-header {
    display: flex;
  }
  .close-header {
    display: flex;
  }
  .back-header-wrap {
    display: block;
  }
  .dim {
    min-width: unset;
  }
  .confirm-modal {
    width: calc(100% - 48px);
  }
  .contract-page {
    .contract-main {
      padding: 20px 24px 30px 24px;
    }
    .contract-history {
      padding: 20px 24px;
      .contents-area {
        .contents-card {
          &:last-child {
            margin-bottom: 13px;
          }
        }
      }
      .contents-empty {
        position: relative;
        min-height: $only_backward_header;
        top: unset;
        left: unset;
        transform: unset;
      }
    }
    .contract-select-building {
      padding: unset;
      padding-top: 30px;
      padding-left: 24px;
      padding-right: 24px;
      position: relative;
      overflow: hidden;
    }
  }
  .error-page {
    position: relative;
    min-height: unset;
    top: unset;
    left: unset;
    transform: unset;
  }
  .user-invite-page {
    .floating-input-area {
      position: sticky;
      padding: 0 24px;
      height: $floating-input-height;
      margin-bottom: unset;
    }
    .user-invite-list {
      .contents-area {
        .contents-card {
          &:last-child {
            margin-bottom: 10px;
          }
        }
        margin-bottom: unset;
      }
      padding: 10px 24px;
      min-height: $userlist-height;
    }
    .user-invite-add {
      padding: 20px 24px;
      .title-wrap {
        margin-bottom: 50px;
      }
    }
    .user-invite-fail {
      padding: 20px 24px;
      .title-wrap {
        margin-bottom: 50px;
      }
    }
  }
}
