.access-log-modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  .content {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .buttons {
    display: flex;
    width: 100%;
    margin-top: 20px;
  }
}
.user-title-btn {
  flex: 1;
}

.user-invite-page {
  .floating-input-area {
    width: 100%;
    height: $floating-input-height;
    position: sticky;
    background-color: $white;
    top: $backward_header_height;
    z-index: $header-z-in;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
  }

  .user-invite-list {
    padding: 10px 24px;
    min-height: $userlist-height;

    h1 {
      font-size: 2.4rem;
    }

    .contents-area {
      // padding-top:50px;
      width: 100%;

      .contents-card {
        width: 100%;
        height: 100%;
        padding: 20px;
        background-color: $bg_light;
        border-radius: 8px;
        margin-bottom: 10px;

        box-sizing: border-box;

        &__main-area {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          height: 100%;

          & > .left-section {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;

            display: flex;
            align-items: center;
            text-align: right;

            height: 100%;

            color: $text-primary3;

            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;

            & > p:first-child {
              font-family: "Roboto";
              font-style: normal;
              font-weight: 700;
              font-size: 15px;
              line-height: 22px;
              /* identical to box height, or 147% */

              display: flex;
              align-items: center;

              /* text/primary */

              color: $text-primary1;

              margin-bottom: 10px;
            }

            & > p:last-child {
              font-family: "Roboto";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              /* identical to box height, or 147% */

              display: flex;
              align-items: center;

              /* text/primary */

              color: $text-primary3;
            }
          }

          & > .right-section {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            & > div:first-child {
              font-family: "Roboto";
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              display: flex;
              align-items: center;
              text-align: right;
              color: $text-primary3;

              margin-bottom: 10px;
            }
          }
        }

        &__info-area {
          padding-top: 15px;

          display: flex;
          align-items: center;
          justify-content: space-between;
          border-top: 1px solid $line_dark;
          margin-top: 16px;

          p {
            font-size: 1.2rem;
            color: $text-primary3;
            line-height: 16px;
            padding-right: 20px;
          }
        }
      }
    }

    .contents-empty {
      min-height: initial !important;
      padding-top: 185px;
    }
  }

  .user-invite-add {
    padding: 20px 24px;

    .title-wrap {
      margin-bottom: 50px;

      h1 {
        font-size: 2.2rem;
        line-height: 32px;
        font-weight: 600;
      }

      p {
        font-size: 1.4rem;
        line-height: 2rem;
        color: $text-primary3;
        letter-spacing: -0.04em;
        padding-top: 10px;
      }
    }

    .added-user-line {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;

      p {
        font-size: 1.5rem;
        line-height: 22px;
        @include font("roboto");
      }

      button {
        width: 24px;
        height: 24px;
      }
    }

    .btn-wrap {
      padding-top: 80px;
    }

    &__invite-btn-wrap {
      padding-top: 30px;
      padding-bottom: 10px;
      display: none;
    }
  }

  .user-invite-fail {
    padding: 20px 24px;

    .title-wrap {
      margin-bottom: 50px;

      h1 {
        font-size: 2.2rem;
        line-height: 32px;
        font-weight: 600;
      }

      p {
        font-size: 1.4rem;
        line-height: 2rem;
        color: $text-primary3;
        letter-spacing: -0.04em;
        padding-top: 10px;
      }
    }

    .index-wrap {
      display: flex;
      align-items: center;

      h2 {
        font-size: 1.8rem;
        font-weight: 600;
      }

      span {
        font-size: 1.4rem;
        padding-left: 0.9rem;
        color: $text-primary3;
      }
    }

    .list-line {
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $line_dark;

      p {
        font-size: 1.4rem;
        white-space: nowrap;
      }
    }
  }

  .user-invite-title {
    display: flex;
    justify-content: space-between;

    & .file-upload-button-container {
      display: flex;
      height: 100%;
      position: relative;
      flex: 1;

      & .popup {
        position: absolute;
        display: none;

        border: 1px solid $line_dark;

        left: 50%;
        transform: translateX(-50%);

        top: 50px;

        z-index: 10;
        padding: 20px;

        & .confirm-modal__contents {
          padding-bottom: 0px;
        }

        & img {
          border-radius: 11px;
        }
      }

      & .popup.on {
        display: block;
      }
    }

    & .file-upload-button {
      padding: 4px 11px;
    }

    & .file-upload-button:before {
      content: "";
      display: block;
      background: url("../../images/icons_excel.svg") no-repeat;

      width: 30px;
      height: 30px;

      float: left;
    }
    display: none;
  }
}

.pagination {
  display: flex;
  & > li {
    list-style: none;
    font-size: 14px;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 30px;
    height: 30px;

    border-radius: 4px;
  }

  & > li:hover {
    cursor: pointer;
    background-color: #f1f1f1;
  }

  & > li.active {
    background-color: #f1f1f1;
  }

  & > li.disabled {
    visibility: hidden;
  }
}
.border-1px-solid {
  border: 1px solid $line_dark;
  border-radius: 1;
}
.excel_upload_info {
  width: 24px;
  height: 24px;

  float: center;
  content: "";
  display: block;
  background: url("../../images/icons_medium_warning.svg") no-repeat;
}

.tooltip-floating-btn-wrap {
  position: absolute;
  width: 473px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 24px;
  padding-right: 24px;
  background-color: white;
  z-index: 100;
}
