.access-log-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .title {
    width: 100%;

    margin-bottom: 50px;
    padding: 0 24px 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .body {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .date-container {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 100%;

      & .datepicker {
        padding: 20px;
        width: 100%;
      }

      & .datechip {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
    }

    .empty-container {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 100%;
      height: 100%;

      padding: 110px 0;

      font-weight: 600;
      font-size: 15px;
    }

    .spinner-container {
      width: 100%;
      height: 100%;

      display: flex;

      align-items: center;
      justify-content: center;

      height: 500px;
    }

    & .list-container {
      margin-bottom: 30px;
    }

    .list-container {
      width: 100%;
      margin-top: 50px;

      & > li {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 20px 0;

        & > div {
          font-size: 14px;
          font-weight: 400;
        }

        border-bottom: 1px solid #dbdbdb;
      }

      & .title {
        width: 230px;
        margin-bottom: 0px;
      }

      & .date {
        width: 150px;
      }

      & .phoneNumber {
        width: 110px;
      }
    }
  }
}

.file-upload-button-container {
  display: flex;
  align-items: center;
  justify-content: center;

  & button {
    width: fit-content;
  }
}

.file-upload-button {
  padding: 4px 11px;
}

.file-upload-button:before {
  content: "";
  display: block;
  background: url("../../images/icons_excel.svg") no-repeat;

  width: 30px;
  height: 30px;

  float: left;
}
