.page-inquiry {
  article {
    height: $only_header;
    display: flex;
    flex-direction: column;
    section {
      padding: 20px 24px;
      flex: 1;
      overflow: auto;
      @include scroll-hidden;
      > div.container {
        margin-bottom: 30px;
      }
    }

    .index-title {
      font-size: 16px;
      position: relative;
      width: fit-content;
      font-weight: 600;
      margin-bottom: 10px;
      &.required::after {
        content: "*";
        position: absolute;
        color: $system-red;
        font-size: 16px;
        right: -8px;
        top: 0;
      }
    }
    textarea {
      width: 100%;
      border: 1px solid $line-dark;
      padding: 20px;
      font-size: 1.5rem;
      color: $text-primary1;
      border-radius: 4px;
      resize: none;
      letter-spacing: -0.6px;
      height: 150px;
      font-family: "Apple SD Gothic Neo";
      &::placeholder {
        color: $text-primary4;
      }
      &:focus {
        outline: none;
      }
    }
    .gray-box {
      width: 100%;
      padding: 18px 20px;
      background-color: $bg-light;
      margin-bottom: 20px;
      &__line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        p:first-child {
          font-size: 14px;
          color: $text-primary2;
        }
        p:last-child {
          font-size: 15px;
          color: $text-primary1;
        }
      }
    }
  }
}
