// erase auto complete input
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-transition: background-color 9999s ease-out;
  // -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  -webkit-text-fill-color: inherit !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: inner-spin-button;
  /***
                 * Below tried and failed: 
                 ***/
  /* -moz-appearance: number-input; */
  /*-ms-appearance: inner-spin-button;*/
  /*appearance: auto;*/
  display: none;
}
// erase outline
input:focus {
  outline: none;
}

.login-input-wrap {
  width: 100%;
  position: relative;
  p {
    display: none;
  }
  .login-input {
    width: 100%;
    box-sizing: border-box;
    height: 54px;
    border: none;
    border-bottom: 1px solid $line_dark;
    color: $text-primary1;
    font-size: 1.6rem;
    line-height: 2.2rem;
    border-radius: 0;
    &:focus {
      border-bottom-color: $text-primary1;
    }
    &::placeholder {
      font-size: 1.6rem;
      line-height: 22px;
      color: $text_primary4;
    }
  }
  &.--invalid {
    .login-input {
      border-bottom-color: $system_red !important;
    }
    p {
      display: block;
      position: absolute;
      bottom: -26px;
      color: $system_red;
      font-size: 1.2rem;
    }
  }
  .check-green-btn,
  .clear-btn {
    position: absolute;
    right: 0;
    top: 10px;
  }
  .only-text {
    position: absolute;
    right: 0;
    top: 13px;
    padding: 10px;
    background-color: transparent;
    border: none;
    font-size: 1.4rem;
    color: $text-primary3;
    font-weight: 400;
    &:disabled {
      color: $text-primary4;
    }
    &:disabled.--loading {
      span {
        opacity: 0;
      }
    }
  }
  .mail-not-coming-btn {
    position: absolute;
    right: 0;
    top: 74px;
    padding-right: 14px;
    background-image: url("../../images/icons_small_arrow_right.svg");
    background-repeat: no-repeat;
    background-position: right;
    background-color: transparent;
    border: none;
    opacity: 0.5;
  }
}

.visitor-input-wrap {
  width: 100%;
  position: relative;
  p {
    display: none;
  }
  .login-input {
    width: 100%;
    box-sizing: border-box;
    height: 64px;
    border: none;
    border-bottom: 1px solid $line_dark;
    font-weight: 500;
    letter-spacing: -0.04em;
    color: $text-primary1;
    font-size: 1.6rem;
    line-height: 2.2rem;
    border-radius: 0;

    &:focus {
      border-bottom-color: $text-primary1;
    }
    &::placeholder {
      font-size: 1.6rem;
      line-height: 22px;
      color: $text_primary4;
      font-weight: 400;
    }
  }
  &.--invalid {
    .login-input {
      border-bottom-color: $system_red !important;
    }
    p {
      display: block;
      color: $system_red;
      font-size: 1.2rem;
      padding-top: 10px;
    }
  }
  .only-text {
    position: absolute;
    right: 0;
    top: 13px;
    padding: 10px;
    background-color: transparent;
    border: none;
    font-size: 1.4rem;
    color: $text-primary1;
    font-weight: 400;
    &:disabled.--loading {
      span {
        opacity: 0;
      }
    }
  }
}
input[type="checkbox"].checkbox,
input[type="radio"].radio {
  display: none;
}

input[type="radio"],
input[type="checkbox"] {
  display: none;
}
input {
  font-family: "Apple SD Gothic Neo", sans-serif;
}
input[type="checkbox"] {
  & + label {
    width: fit-content;
  }
  & + label .base-checkbox {
    height: 24px;
    width: 24px;
    display: inline-block;
    padding: 0;
    margin-top: 1px;
    background: url("../../images/icons_checkbox.svg") no-repeat;
    background-size: 24px;
    background-position: center;
    border: none;
  }

  &:checked + label .base-checkbox {
    background: url("../../images/icons_checkbox_checked.svg") no-repeat;
    background-size: 24px;
    background-position: center;
  }
  //   &:disabled + label .base-checkbox {
  //     border: none;
  //   }
}

// 라디오버튼
input[type="radio"] {
  & + label .base-radio {
    height: 20px;
    width: 20px;
    border-radius: 50px;
    display: inline-block;
    padding: 0;
    border: 1px solid $line_dark;
    margin-top: 1px;
    position: relative;
    background-color: $white;

    &:hover {
      border: 1px solid $text-primary1;
    }
  }

  &:checked + label .base-radio {
    border: 1px solid $text-primary1;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: $text-primary1;
    }
  }

  &:disabled + label .base-radio {
    background-color: $bg_dark;
    border: 1px solid $line_light;
  }
}

// disabled 처럼 보이는 인풋
.like-disabled-input {
  width: 100%;
  border-radius: 4px;
  background-color: $bg-light;
  height: 54px;
  padding: 17px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__prefix {
    white-space: nowrap;
    font-size: 1.4rem;
  }
  &__value {
    width: 100%;
    font-size: 1.5rem;
    text-align: right;
    font-weight: 400;
    white-space: nowrap;
    letter-spacing: -0.04em;
    font-family: "Apple SD Gothic Neo", sans-serif;
    .password {
      letter-spacing: 0.04em;
    }
  }
  &__suffix {
    margin-left: 10px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    button.text-underline {
      padding: 0 6px;
      text-decoration: underline;
      border: none;
      background-color: transparent;
      color: $text-primary1;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

.profile-input-wrap {
  width: 100%;
  position: relative;
  .profile-input {
    width: 100%;
    box-sizing: border-box;
    height: 64px;
    border: none;
    border-bottom: 1px solid $line_dark;
    color: $text-primary1;
    font-size: 1.6rem;
    line-height: 2.2rem;
    border-radius: 0;
    &:read-only {
      color: $text-primary1;
      &:focus {
        border-bottom-color: $line-dark;
      }
    }
    &:focus {
      border-bottom-color: $text-primary1;
    }

    &::placeholder {
      font-size: 1.6rem;
      line-height: 22px;
      color: $text_primary4;
    }
  }
  &.--invalid {
    .profile-input {
      border-bottom-color: $system_red !important;
    }
  }
  p.validation-text {
    display: block;
    color: $system_red;
    font-size: 1.2rem;
    position: relative;
    width: fit-content;
    &::after {
      content: "";
      width: 14px;
      height: 14px;
      position: absolute;
      right: -18px;
      margin-top: 1px;
      background-image: url("../../images/icons_small_info.svg");
    }
  }
  .only-text {
    position: absolute;
    right: 0;
    top: 13px;
    padding: 10px;
    background-color: transparent;
    border: none;
    font-size: 1.4rem;
    color: $text-primary3;
    font-weight: 400;
    &:disabled {
      color: $text-primary4;
    }
    &:disabled.--loading {
      span {
        opacity: 0;
      }
    }
  }

  .check-green-only,
  .check-green-btn,
  .clear-btn {
    position: absolute;
    right: 0;
    top: 10px;
  }
}

.base-input-wrap {
  width: 100%;
  position: relative;
  &.search-case {
    button {
      position: absolute;
      right: 14px;
      top: 15px;
      width: 24px;
      height: 24px;
    }
    .base-input {
      height: 54px;
      padding-left: 10px;
      padding-right: 38px;
      border: none;
      border-radius: 4px;
      background-color: $bg-light;

      &::placeholder {
        font-size: 1.6rem;
        line-height: 22px;
        color: $text_primary3;
      }
    }
  }
  .base-input {
    width: 100%;
    box-sizing: border-box;
    height: 54px;
    border: none;
    border-bottom: 1px solid $line_dark;
    color: $text-primary1;
    font-size: 1.6rem;
    line-height: 2.2rem;
    border-radius: 0;
    &:read-only {
      color: $text-primary1;
      &:focus {
        border-bottom-color: $line-dark;
      }
    }
    &:focus {
      border-bottom-color: $text-primary1;
    }

    &::placeholder {
      font-size: 1.6rem;
      line-height: 22px;
      color: $text_primary4;
    }
    &.border-bottom-red {
      border-bottom-color: $system_red !important;
    }
    &.--invalid {
      border-bottom-color: $system_red !important;
    }
  }

  .validation-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    z-index: 1;
    &.position-absolute {
      position: absolute;
      bottom: -23px;
    }
    // &__left {
    // }
    // &__right {
    // }
  }
  .validation-text {
    display: block;
    color: $system_red;
    font-size: 1.2rem;
    position: relative;
    width: fit-content;
    &.ic-info::after {
      content: "";
      width: 14px;
      height: 14px;
      position: absolute;
      right: -18px;
      margin-top: 1px;
      background-image: url("../../images/icons_small_info.svg");
    }
  }
  .only-text,
  .only-text-btn {
    position: absolute;
    right: 0;
    top: 8px;
    padding: 10px;
    background-color: transparent;
    border: none;
    font-size: 1.4rem;
    color: $text-primary1;
    font-weight: 400;
    &:disabled {
      color: $text-primary4;
    }
    &:disabled.--loading {
      span {
        opacity: 0;
      }
    }
    &.--loading {
      min-height: 54px;
      top: 0;
    }
  }

  .check-green-only,
  .check-green-btn,
  .clear-btn {
    position: absolute;
    right: 0;
    top: 10px;
  }
}

.base-textarea-wrap {
  width: 100%;
  position: relative;
  textarea {
    width: 100%;
    min-height: 100px;
    box-sizing: border-box;
    border-radius: 4px;
    border: none;
    border-bottom: 1px solid $line_light;
    padding-top: 16px;
    font-size: 1.6rem;
    line-height: 22px;
    border-radius: 0;
    resize: none;
    //  &::-webkit-scrollbar {
    //     display: none;
    //   }
    &::placeholder {
      color: $text_primary4;
      font-weight: 400;
    }
    &:focus,
    &:focus:hover {
      outline: none;
      border-bottom: 1px solid $text-primary1;
    }
    &:hover {
      border-color: 1px solid $text-primary1;
    }
    @include font("apple");
  }
}
