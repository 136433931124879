
.announcement-page {
    width: 100%;
    display: flex;
    align-items: center;
    // flex-direction: column;
    justify-content: center;
    text-align: center;
    &.with-header{
        height: $only_backward_header;
    }
    .message-wrap {
        padding-top: 30px;
        h2 {
            font-size: 1.8rem;
            font-weight: 600;
            margin-bottom: 10px;
        }
        p {
            font-size: 1.5rem;
            line-height: 22px;
        }
        & ~ .error-message-wrap {
            padding-top: 40px;
        }
    }
    .icon {
        width: 62px;
        height: 85px;
        margin: 0 auto;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        &.ic-question {
            background-image: url("../../images/icons_underline_question.svg");
        }
        &.ic-wifi {
            background-image: url("../../images/icons_underline_wifi.svg");
        }
        &.ic-setting {
            background-image: url("../../images/icons_underline_setting.svg");
        }
        &.ic-none {
            background-image: url("../../images/icons_underline_none.svg");
        }
        &.ic-check {
            background-image: url("../../images/icons_complete.svg");
        }
    }
}
