.base-toast {
  width: calc(100vw - 48px);
  height: auto;
  min-height: 36px;
  max-width: calc(500px - 48px);
  max-height: 100px;
  border-radius: 4px;
  background-color: $text-primary1;
  animation: modal-show 0.2s;
  overflow: hidden;
  position: fixed;
  padding: 8px 10px;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  z-index: $toast-z-in;
  p {
    font-size: 1.4rem;
    color: $white;
  }
  @include desktop {
    width: calc(475px - 48px);
    left: calc(50% + 24px);
    transform: unset;
    animation: modal-show-desktop 0.2s;
  }
}

@keyframes modal-show {
  from {
    transform: translateX(-50%) scale(0.8);
  }
  to {
    transform: translateX(-50%) scale(1);
  }
}
@keyframes modal-show-desktop {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}
