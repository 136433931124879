.dim {
  position: fixed;
  width: 100%;
  height: 100%;
  max-width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  margin: 0 auto;
  &.bottom {
    align-items: flex-end;
  }
  &.all-black {
    background-color: #000;
    display: block;
    align-items: unset;
  }
  @include desktop {
    width: $bp-mobile;
    left: 50%;
    margin: unset;
  }
}

.base-abstract-modal {
  width: calc(100% - 48px);
  height: auto;
  max-height: $modal-max-height;
  margin: 0 auto;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
}
.confirm-modal {
  min-width: 200px;
  width: calc(100% - 48px);
  height: auto;
  max-height: 690px;
  margin: 0 auto;
  padding: 30px 60px 20px 60px;
  border-radius: 8px;
  background-color: #fff;
  animation: top-to-bottom 0.3s;
  overflow: hidden;
  position: relative;
  &__contents {
    padding-bottom: 30px;
    font-size: 1.4rem;
    text-align: center;
  }
  &__btn-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .base-btn {
    width: fit-content;
    min-width: 120px;
    height: 40px;
    font-size: 1.5rem;
    font-weight: 400;
  }
}

.base-modal {
  min-width: 200px;
  width: calc(100% - 48px);
  height: auto;
  max-height: calc(#{$full_height} - 48px);
  margin: 0 auto;
  padding: 0 20px;
  padding-bottom: 22px;
  border-radius: 10px;
  background-color: #fff;
  animation: top-to-bottom 0.3s;

  position: relative;

  &__title {
    width: calc(100% - 40px);
    height: $modal-title-height;
    display: flex;
    align-items: center;
    position: absolute;
    background-color: $white;
    top: 0;
    justify-content: space-between;
    span {
      font-size: 1.8rem;
      font-weight: 600;
      margin-top: 10px;
    }
  }
  &__contents {
    font-size: 1.4rem;
    text-align: center;
    height: auto;
    max-height: $modal-content-height;
    padding-top: $modal-title-height;
    overflow: auto;
    @include scroll-hidden;
  }
  &__btn-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.fixed-width {
    width: 800px;

    .base-modal__contents {
      overflow: visible;
    }
  }
}

.base-full-modal {
  width: 100%;
  max-width: 100%;
  min-height: $full_height;
  max-height: $full_height;
  background-color: #fff;
  animation: bottom-to-top 0.3s;
  position: relative;
  overflow: hidden;
  &__contents {
    font-size: 1.4rem;
    min-height: $full_height;
    max-height: $full_height;
    overflow: hidden;
    @include scroll-hidden;
  }
  &.header-exist {
    &__contents {
      font-size: 1.4rem;
      min-height: $only_backward_header;
      max-height: $only_backward_header;
      overflow: hidden;
      @include scroll-hidden;
    }
  }
  iframe {
    border: none;
    display: block;
    width: 100%;
    min-height: $only_backward_header;
  }
}

.base-bottom-sheet {
  min-width: 200px;
  width: 100%;
  height: auto;
  max-height: 584px;
  margin: 0 auto;
  padding: 20px 24px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #fff;
  animation: bottom-to-top 0.3s;
  overflow: hidden;
  position: relative;
  &__title {
    width: 100%;
    // height: 63px;
    padding: 20px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h2 {
      font-size: 1.8rem;
      font-weight: 600;
      // margin-top: 10px;
    }
    p {
      font-size: 1.2rem;
      padding-bottom: 2px;
      @include ellipsis;
    }
    &.with-index-title {
      display: block;
    }
  }
  &__contents {
    font-size: 1.4rem;
    text-align: center;
  }
  &__btn-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.scrollable {
    padding: 0px;
    .base-bottom-sheet__title {
      padding: 20px 24px;
      padding-top: 30px;
    }
    .base-bottom-sheet__contents {
      overflow-y: auto;
      max-height: calc(584px - 63px);
      padding: 0 24px;
      @include scroll-hidden;
    }
  }
}

@keyframes top-to-bottom {
  from {
    opacity: 1;
    margin-top: -50px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
}

@keyframes bottom-to-top {
  from {
    opacity: 0;
    margin-bottom: -300px;
  }
  to {
    opacity: 1;
  }
}

@keyframes modal-bg-show {
  from {
    opacity: 0;
    margin-bottom: 0;
  }
  to {
    opacity: 1;
  }
}

// custom modal
.product-info-modal {
  // 공간 상품 안내 모달
  section:first-child {
    border-bottom: 1px solid $line_dark;
  }
  ul {
    margin-left: 20px;
    font-size: 1.5rem;
    line-height: 22px;
  }
}
.product-use-modal {
  // 이용 안내 모달
  ul {
    margin-left: 20px;
    font-size: 1.5rem;
    line-height: 22px;
  }
}
.product-admincost-modal {
  // 관리비 포함 항목 모달
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 54px;
  row-gap: 30px;
  padding-top: 20px;
  > div {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  .icon {
    position: relative;
    &.disabled {
      opacity: 0.3;
    }
  }
  .icon::before {
    content: "";
    position: absolute;
    top: 0;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .icon.icon-edit::before {
    background-image: url("../../images/icons_large_edit.svg");
  }
  .icon.icon-general-clean::before {
    background-image: url("../../images/icons_large_general_clean.svg");
  }
  .icon.icon-clean::before {
    background-image: url("../../images/icons_large_clean.svg");
  }
  .icon.icon-electric::before {
    background-image: url("../../images/icons_large_electric.svg");
  }
  .icon.icon-general-water::before {
    background-image: url("../../images/icons_large_general_water.svg");
  }
  .icon.icon-general-gas::before {
    background-image: url("../../images/icons_large_general_gas.svg");
  }
  .icon.icon-electro::before {
    background-image: url("../../images/icons_large_electro.svg");
  }
  .icon.icon-water::before {
    background-image: url("../../images/icons_large_water.svg");
  }
  .icon.icon-gas::before {
    background-image: url("../../images/icons_large_gas.svg");
  }
}
.product-parking-modal {
  //주차장 안내 모달
  padding-top: 20px;
  text-align: left;
  section {
    display: flex;
    align-items: center;
    &:last-child {
      border-top: 1px solid $line_dark;
    }
    span {
      font-size: 1.5rem;
      line-height: 22px;
      margin-bottom: 4px;
    }
    p {
      line-height: 20px;
      color: $text-primary3;
      font-size: 1.4rem;
      letter-spacing: -0.04em;
    }
    .img-wrap {
      width: 120px;
      min-width: 120px;
      height: 90px;
      padding: 10px;
      margin-right: 10px;
      background-color: $bg_light;
    }
    .self-img {
      width: 100%;
      height: 70px;
      background-image: url("../../images/icons_others_park1.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      box-sizing: border-box;
    }
    .mechanical-img {
      width: 100px;
      height: 70px;
      background-color: $bg_light;
      background-image: url("../../images/icons_others_park2.svg");
      background-repeat: no-repeat;
      background-position: center;
      box-sizing: border-box;
    }
  }
}

.email-auth-info-modal {
  text-align: left;
  h1 {
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 26px;
    margin-bottom: 20px;
  }
  span {
    font-size: 1.5rem;
    line-height: 22px;
  }
  p {
    font-size: 1.5rem;
    line-height: 22px;
  }
  ul {
    padding-left: 24px;
    li {
      // list-style: none;
      font-size: 1.5rem;
      line-height: 22px;
      letter-spacing: -0.04em;
      color: $text-primary1;
      position: relative;
      // &:before {
      //   content: "·";
      //   position: absolute;
      //   left: 0;
      //   font-weight:bold;
      //   font-size: 30px;
      //   vertical-align: middle;
      //   line-height: 20px;
      // }
    }
  }
}
.terms-of-service {
  text-align: left;
  line-height: 22px;
  font-size: 1.5rem;
}

// custom bottomsheet
.product-question-sheet {
  padding-top: 30px;
  padding-bottom: 20px;
  .text-wrap {
    padding: 20px;
    margin-bottom: 20px;
    span {
      font-size: 1.8rem;
      line-height: 26px;
    }
    p {
      margin-top: 4px;
      color: $text-primary3;
      font-size: 1.4rem;
      line-height: 20px;
    }
  }
}
.taap-appstore-sheet {
  padding-top: 40px;
  .main-text {
    font-size: 2.2rem;
    line-height: 32px;
    font-weight: 600;
    letter-spacing: -0.04em;
  }
  .contents {
    padding-top: 60px;
    img {
      width: 110px;
      border-radius: 20px;
    }
    p {
      padding-top: 20px;
      font-size: 1.4rem;
      line-height: 20px;
    }
  }
  .btn-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 80px;
    .app-btn {
      width: 100%;
      height: 50px;
      background-color: transparent;
      border: none;
      &.app-google {
        background-image: url("../../images/app_google.svg");
        background-repeat: no-repeat;
        background-position: right;
      }
      &.app-apple {
        background-image: url("../../images/app_apple.svg");
        background-repeat: no-repeat;
        background-position: left;
      }
    }
  }
}
.space-select-sheet {
  text-align: left;
  .space-list {
    &__item {
      padding-top: 20px;
      padding-bottom: 24px;
      border-bottom: 1px solid $line_dark;
      &:last-child {
        border-bottom: none;
      }
      display: flex;
      align-items: center;
      cursor: pointer;
      // justify-content: space-between;
      img {
        width: 60px;
        height: 60px;
        border-radius: 4px;
        margin-right: 20px;
        min-width: 60px;
        background-position: center;
      }
    }
    &__item-wrap {
      display: flex;
      align-items: center;
      width: 100%;
    }
    &__item-info {
      width: 100%;
      max-width: 100%;
      overflow: hidden;
      p {
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 4px;
        @include ellipsis(2);
      }
      span {
        font-size: 1.2rem;
        color: $text-primary3;
        @include ellipsis;
        display: block;
      }
    }
    &__item-selected {
      width: 40px;
      min-width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 15px;
    }
    &__item-checked {
      width: 30px;
      height: 30px;
      background-image: url("../../images/icons_checkbox_checked.svg");
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

.contract-select-sheet {
  text-align: left;
  .building-list {
    &__item {
      padding-top: 30px;
      &:first-child {
        padding-top: 20px;
      }
      &:last-child {
        padding-bottom: 30px;
      }
      display: flex;
      align-items: center;
      cursor: pointer;
      // justify-content: space-between;
    }
    &__item-wrap {
      display: flex;
      align-items: center;
      width: 100%;
    }
    &__item-info {
      width: 100%;
      max-width: 100%;
      overflow: hidden;
      p {
        font-size: 1.5rem;
        font-weight: 400;
        @include ellipsis(2);
      }
    }
    &__item-default {
      width: 24px;
      min-width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 15px;
    }
    &__item-checked {
      margin-left: 15px;
      width: 24px;
      height: 24px;
      background-image: url("../../images/icons_check.svg");
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
.section-select-sheet {
  text-align: left;
  .section-list {
    &__item {
      list-style: none;
      font-size: 1.5rem;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 14px;
      position: relative;
      &.--active::after {
        content: "";
        position: absolute;
        right: 0;
        width: 24px;
        height: 24px;
        background-image: url("../../images/icons_check.svg");
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}
.visit-purpose-sheet {
  .purpose-list {
    padding: 10px 0px 25px 0px;
    &__item {
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1.8rem;
      position: relative;
      cursor: pointer;
      &.--active::after {
        content: "";
        position: absolute;
        width: 24px;
        height: 24px;
        background-image: url("../../images/icons_check.svg");
        background-repeat: no-repeat;
        background-size: contain;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
.visitor-confirm-modal {
  .base-btn {
    min-width: 100px;
    font-size: 1.8rem;
  }
}

.terms-of-use-modal {
  padding: 30px 0px 0px 0px;
  height: auto;
  max-height: inherit;
  display: flex;
  flex-direction: column;
  &__title {
    padding: 0 20px;
    p {
      font-size: 1.8rem;
      font-weight: 600;
    }
    margin-bottom: 20px;
  }
  &__contents {
    padding: 0 20px;
    height: auto;
    overflow-y: auto;
    p {
      font-size: 1.6rem;
    }
  }
  &__btn-wrap {
    padding: 20px;
    margin: 0 auto;
    .base-btn {
      font-weight: 400;
      font-size: 1.8rem;
    }
  }
}
.visitor-agree-terms-modal {
  max-width: 500px;
  padding: 30px 20px 20px 20px;
  h1 {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 20px;
    letter-spacing: -0.72px;
  }
  .base-checkbox {
    margin-top: 0 !important;
  }
  p {
    font-size: 1.5rem;
    line-height: 22px;
    margin-bottom: 4px;
  }

  .input-wrap {
    width: 100%;
    margin: 30px auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    input {
      width: 200px;
      max-width: 200px;
      height: 54px;
      font-size: 16px;
      padding: 0;
      text-align: center;
      border: none;
      border-bottom: 1px solid $line_dark;
      line-height: 22px;
      color: $text-primary1;
      font-family: "Roboto";
      &::placeholder {
        color: $text-primary4;
        letter-spacing: -0.64px;
      }
    }
    p {
      margin-top: 4px;
      color: $system-red;
      font-size: 1.3rem;
    }
  }
  .text-deco {
    color: text-;
  }
}
.visitor-privacy-policy-modal {
  max-width: 500px;
  padding: 30px 20px 20px 20px;
  h1 {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 20px;
    letter-spacing: -0.72px;
  }
  p {
    font-size: 1.4rem;
    white-space: pre-wrap;
  }
  h2 {
    font-size: 1.4rem;
    font-weight: 600;
  }
}

.share-bottom-sheet {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-bottom: 60px;
  h2 {
    font-size: 20px;
    line-height: 32px; /* 160% */
    letter-spacing: -0.8px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .copy-area {
    border: 1px solid $line-dark;
    border-radius: 4px;
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      font-size: 15px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    button {
      margin-left: 10px;
    }
  }
}

.base-cs-modal {
  article {
    height: $only_header;
    display: flex;
    flex-direction: column;
    section {
      padding: 20px 24px;
      flex: 1;
      overflow: auto;
      @include scroll-hidden;
      > div.container {
        margin-bottom: 30px;
      }
    }

    .index-title {
      font-size: 16px;
      position: relative;
      width: fit-content;
      font-weight: 600;
      margin-bottom: 10px;
      &.required::after {
        content: "*";
        position: absolute;
        color: $system-red;
        font-size: 16px;
        right: -8px;
        top: 0;
      }
    }
    textarea {
      width: 100%;
      border: 1px solid $line-dark;
      padding: 20px;
      font-size: 1.5rem;
      color: $text-primary1;
      border-radius: 4px;
      resize: none;
      letter-spacing: -0.6px;
      height: 150px;
      font-family: "Apple SD Gothic Neo";
      &::placeholder {
        color: $text-primary4;
      }
    }
    .gray-box {
      width: 100%;
      padding: 18px 20px;
      background-color: $bg-light;
      margin-bottom: 20px;
      &__line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        p:first-child {
          font-size: 14px;
          color: $text-primary2;
        }
        p:last-child {
          font-size: 15px;
          color: $text-primary1;
        }
      }
    }
  }
}
