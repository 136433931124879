.base-datepicker {
  position: relative;
  width: 100%;

  &__prefix {
    position: absolute;
    top: 17px;
    left: 0;
    z-index: 1;
    font-size: 1.6rem;
    color: $text-primary4;
  }

  input {
    height: 54px;
    width: 100%;
    border: none;
    border-bottom: 1px solid $line_dark;
    text-align: right;
    font-size: 1.6rem;
    padding-right: 22px;
    position: relative;
    background-image: url("../../images/icons_chevron_down.svg");
    background-repeat: no-repeat;
    background-position: right;

    &.react-datepicker-ignore-onclickoutside {
      background-image: url("../../images/icons_chevron_up.svg");
    }
  }

  .react-datepicker {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    border: none;

    &-popper {
      z-index: 10;
      width: 100%;
    }

    &__navigation {
      &--previous {
        top: 20px;
        right: 58px;
        left: unset;
      }

      &--next {
        top: 20px;
        right: 20px;
      }

      &-icon::before {
        border-width: 2px 2px 0 0;
        border-color: $text-primary1;
      }
    }

    &__month-container {
      width: 100%;
    }

    &__header {
      background-color: #fff;
      border: none;
      padding-top: 24px;

      &:not(.react-datepicker__header--has-time-select) {
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
      }
    }

    &__current-month {
      text-align: left;
      font-weight: 400;
      font-size: 1.6rem;
      padding-left: 22px;
      padding-right: 22px;
      padding-bottom: 30px;
    }

    &__month {
      // margin-left:10px;
      // margin-right:10px;
      padding-left: 10px;
      padding-right: 10px;
      margin: 0;
      padding-bottom: 20px;
    }

    &__week {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: none;
      }
    }

    &__day {
      width: 40px;
      height: 40px;
      font-size: 1.6rem;
      // padding:14px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50% !important;

      &--today {
        border: 1px solid $text-primary1;
        font-weight: 400;
      }

      &--outside-month {
        pointer-events: none;
        color: $text-primary4;

      }

      &--selected {
        background-color: $text-primary1;
        font-weight: 400;

        &:hover {
          background-color: $text-primary1;
        }
      }

      &--keyboard-selected {
        background-color: $text-primary1;
        font-weight: 400;
      }

      &-names {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: unset;
      }

      &-name {
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: unset;
        font-size: 1.1rem;
        color: $text-primary3;
      }

      &--in-range {
        background-color: $text-primary1;
      }

      &--in-selecting-range {
        background-color: $text-primary3 !important;
      }
    }
  }



  // .react-select {
  //   &__value-container {
  //     padding: 0;
  //     padding-right: 6px;
  //   }
  //   &__single-value {
  //     font-size: 1.6rem;
  //     text-align: right;
  //   }
  //   &__indicator-separator {
  //     display: none;
  //   }
  //   &__placeholder {
  //     font-size: 1.6rem;
  //     color: $text-primary1;
  //     white-space: nowrap;
  //     border-color: #000;
  //     box-shadow: none;
  //     text-align: right;
  //   }
  //   &__control--is-focused {
  //     // border-color: #000;
  //     box-shadow: unset;
  //   }
  //   &__control--menu-is-open {
  //     .react-select__indicator {
  //       transform: rotate(0deg);
  //     }
  //   }
  //   &__menu {
  //     z-index: $select-z-in;
  //     width: fit-content;
  //     right: 0;
  //     box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  //   }
  //   &__menu-list {
  //     padding-top: 10px;

  //     .react-select__option {
  //       font-size: 1.5rem;
  //     }
  //   }
  //   &__option {
  //     background-color: $white;
  //     padding-top: 14px;
  //     padding-bottom: 14px;
  //     padding-left: 40px;
  //     padding-right: 50px;
  //   }
  //   &__option--is-focused {
  //     background-color: white;
  //   }
  //   &__option--is-selected {
  //     // background-color: $line_dark;
  //     color: $text-primary1;
  //     position: relative;
  //     &:active {
  //       background-color: $line_dark !important;
  //     }
  //     &::after {
  //       content: "";
  //       position: absolute;
  //       right: 22px;
  //       top: 50%;
  //       transform: translateY(-60%);
  //       width: 24px;
  //       height: 24px;
  //       background-image: url("../../images/icons_check.svg");
  //       background-position: center;
  //       background-repeat: no-repeat;
  //     }
  //   }
  //   &__option:not(.react-select__option--is-selected) {
  //     &:hover,
  //     &:active {
  //       background-color: $line_gray;
  //     }
  //   }
  //   &__indicator {
  //     color: #000;
  //     background-image: url("../../images/icons_chevron_down.svg");
  //     background-repeat: no-repeat;
  //     background-position: center;
  //     transform: rotate(180deg);
  //     svg {
  //       display: none;
  //       width: 16px;
  //       height: 16px;
  //     }
  //   }
  // }
}

.range-datepicker {
  .react-datepicker__day {
    color: #191919;
  }

  .react-datepicker__day:hover,
  .react-datepicker__day--in-range:hover {
    background-color: #ffffff;
    border: 1px solid #191919;
    color: #191919;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: #191919;
    color: #ffffff;
  }

  .react-datepicker__day--in-selecting-range {
    background-color: #f2f4f6 !important;
    color: #191919;
  }

  .react-datepicker__day--selecting-range-start,
  .react-datepicker__day--selecting-range-start:hover {
    background-color: #191919 !important;
    color: #ffffff
  }



  .react-datepicker__day--selecting-range-end,
  .react-datepicker__day--selecting-range-end:hover {
    background-color: #191919 !important;
    color: #ffffff;
  }

  .react-datepicker__day--in-range {
    background-color: #f2f4f6;
  }

  .react-datepicker__day--range-start {
    background-color: #191919;
    color: #ffffff;
  }

  .react-datepicker__day--range-end {
    background-color: #191919;
    color: #ffffff;
  }

  .react-datepicker__day--outside-month {
    color: #999999;
  }
}