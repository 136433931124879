.payment-page {
  .payment-history {
    .contents-area {
      padding: 20px 24px;
      width: 100%;
      max-height: calc(#{$only_header} - 62px);
      overflow-y: auto;
      @include scroll-hidden;
    }
    .contents-empty {
      min-height: calc(#{$only_header} - 62px);
    }
  }

  .contents-card {
    width: 100%;
    min-width: 310px;
    height: 120px;
    padding: 20px;
    background-color: $bg_light;
    border-radius: 8px;
    margin-bottom: 10px;
    &:last-child {
      // margin-right: 24px;
    }
    &__title-area {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 16px;
      border-bottom: 1px solid $line_dark;
      p {
        font-size: 1.4rem;
        font-weight: 400;
        color: $text-primary3;
        position: relative;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: 10px;
        line-height: 20px;
        @include font("roboto");
      }
    }
    &__info-area {
      margin-top: 16px;
      min-height: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        font-size: 1.5rem;
        color: $text-primary1;
        line-height: 22px;
      }
      button {
        width: 24px;
        height: 24px;
        border: none;
        background: url("../../images/icons_chevron_right.svg") no-repeat center;
        opacity: 0.5;
      }
      .value {
        @include font("roboto");
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 22px;
      }
      &.detail {
        button {
          display: none;
        }
      }
    }
  }
  .payment-main {
    padding: 20px 24px 0px 24px;
    h1 {
      font-size: 2.4rem;
      line-height: 34px;
      letter-spacing: -0.04em;
      padding-bottom: 50px;
    }
  }
  .payment-price {
    padding: 30px 24px;
    h2 {
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 22px;
      padding-bottom: 20px;
    }
    &__title-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 20px;
      h2 {
        padding-bottom: 0px;
      }
    }
    &__wrap {
      & ~ .payment-price__wrap {
        border-top: 1px solid $line_dark;
        padding-top: 30px;
        margin-top: 30px;
      }
    }
    &-bottom-line {
      border-bottom: 1px solid $line_dark;
      padding-bottom: 30px;
      margin-bottom: 30px;
    }
    &-line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      white-space: nowrap;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &-index {
      font-size: 1.5rem;
      line-height: 22px;
      .desc {
        font-size: 1.2rem;
        margin-left: 5px;
      }
    }
    &-value {
      font-size: 1.5rem;
      line-height: 22px;
      font-family: "Roboto", sans-serif;
    }
  }
  ul {
    // padding-top:20px;
    li {
      list-style: none;
      font-size: 1.4rem;
      line-height: 20px;
      letter-spacing: -0.04em;
      color: $text-primary3;
      position: relative;
      padding-left: 12px;
      &:before {
        content: "·";
        position: absolute;
        left: 0;
        font-size: 20px;
        vertical-align: middle;
        line-height: 20px;
      }
    }
  }
}

.custom-tab {
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  &__item {
    width: 100%;
    background-color: $white;
    border-bottom: 2px solid $line-light;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    span {
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 22px;
      color: $text-primary3;
    }
    &.--active {
      span {
        color: $text-primary1;
        font-weight: 600;
      }
      border-color: $text-primary1;
    }
  }
}
