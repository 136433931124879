.facility-detail {
  .facility-info {
    &__main {
      padding: 20px 24px 30px 24px;
      .main-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;
      }
      .main-title {
        font-size: 2rem;
        font-weight: 600;
        line-height: 32px;
      }

      .sub-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .price-info {
          display: flex;
          align-items: center;
          font-size: 1.2rem;
          line-height: 16px;
        }
        .price {
          font-size: 1.4rem;
          line-height: 26px;
          span {
            font-size: 1.8rem;
            font-weight: 700;
            font-family: "Roboto";
          }
        }
        .info {
          font-size: 1.2rem;
          color: $text-primary3;
          padding-left: 4px;
        }
        .description {
          font-size: 1.2rem;
          color: $text-primary3;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-height: 20px;
        }
      }
      .additional-info {
        border-top: 1px solid $text-primary1;
        border-bottom: 1px solid $text-primary1;
        padding: 20px 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 20px;
        margin-top: 20px;
        .info-wrap {
          &__index {
            font-size: 12px;
            font-weight: 400;
            color: $text-primary3;
            line-height: 16px;
          }
          &__value {
            margin-top: 2px;
            p {
              font-size: 1.4rem;
              font-weight: 600;
              line-height: 20px;
            }
          }
        }
      }
    }
    &__calendar-time {
      h2 {
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 20px;
        padding-left: 24px;
      }
      .calendar-area {
        padding-top: 40px;
      }
      .time-area {
        padding: 20px 24px 30px;
        .time-picker {
          margin-top: 20px;
          // margin-left: 24px;
          // margin-right: 24px;

          overflow-x: auto;
          @include scroll-hidden;
          &__index {
            display: grid;
            grid-template-columns: repeat(50, 47px);
            height: 22px;
            & > div {
              border-left: 1px solid $line_dark;
              position: relative;
              margin: 0 -0.8px;
              span {
                display: block;
                background-color: $white;
                position: absolute;
                top: 0px;
                font-size: 10px;
                left: -1px;
                padding-left: 4px;
                z-index: 10;
              }
            }
          }
          &__grid {
            display: grid;
            grid-template-columns: repeat(50, 47px);
            height: 48px;
            div {
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 1.2rem;
              color: $text-primary4;
              font-family: "Roboto";
              cursor: pointer;

              border-top: 1px solid $line_dark;
              border-right: 1px solid $line_dark;

              border-bottom: 1px solid $line_dark;

              &:first-child {
                border-left: 1px solid $line_dark;
              }
              &:last-child {
                border-right: 1px solid $line_dark;
              }

              &.--disabled {
                background-color: $bg_dark;
                border-right: none;
              }

              &.--active {
                background-color: $text-primary1;

                color: $white;
              }

              // 마지막 요소인 경우
            }
          }
        }
      }
    }
    &__facility-info {
      padding: 30px 24px;
      h2 {
        font-size: 1.6rem;
        font-weight: 600;
        margin-bottom: 20px;
      }
      .description {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.56px;
        text-align: justify;
        margin-bottom: 20px;
      }
      .info-title {
        font-size: 1.4rem;
        font-weight: 600;
        margin-bottom: 20px;
      }
      .info-line {
        display: flex;
        align-items: start;
        margin-top: 16px;
        font-size: 1.4rem;
        span {
          display: block;
          width: 93px;
          color: $text-primary3;
        }
      }
      .address-area {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .address {
          display: flex;
          flex-direction: column;
          gap: 2px;
          p {
            font-size: 1.4rem;
            color: $text-primary1;
            line-height: 20px;
          }
        }
        button {
          text-decoration: underline;
          font-size: 12px;
          color: $text-primary3;
          border: none;
          background-color: transparent;
        }
      }
      .map-container {
        width: 100%;
        max-width: 100%;
        height: 200px;
        max-height: 272px;
        margin-top: 12px;
      }
    }
  }
}

.content-layout {
  padding: 30px 24px;
}

.facility-title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-size: 16px;
    font-weight: 600;
  }
}

.facility-description {
  min-width: 342px;
  font-size: 14px;
  color: $text-primary3;
  padding-top: 10px;
  font-weight: 400;
  p {
    width: 100%;
  }
}

.chip {
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 12px;
  &.blue {
    color: #217bce;
    border: 1px solid #217bce;
  }

  &.green {
    color: #26a687;
    border: 1px solid #26a687;
  }
}

.facility-hour-area {
  // height: 212px;
  border-radius: 4px;

  ul {
    padding: 20px 16px;
  }
}

.facility-content {
  font-size: 14px;
}

.divider-top-bottom {
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  margin: 30px 0;
  padding: 30px 0;
}

.custom-calendar-button {
  &__prev {
    width: 24px;
    height: 24px;
    background-image: url("../../images/icons_prev_vector.svg");
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-size: contain;
    background-color: transparent;
  }
  &__prev-gray {
    width: 24px;
    height: 24px;
    background-image: url("../../images/icons_prev_vector_gray.svg");
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-size: contain;
    background-color: transparent;
  }
  &__next {
    width: 24px;
    height: 24px;
    background-image: url("../../images/icons_next_vector.svg");
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-size: contain;
    background-color: transparent;
  }
}
