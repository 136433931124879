// height value
$header-height: 43px;
$header-pc-height: 80px;
$backward_header_height: 43px;
$floating-input-height: 94px;
$floating-btn-height: 74px;
$backward-floating-height: $backward_header_height + $floating-btn-height; // 백헤더 + 플로팅버튼
$backwrad-search: $backward_header_height + $floating-input-height; // 백헤더 + 검색
$backward-search-floating: $backwrad-search + $floating-btn-height; // 백헤더 + 검색 + 플로팅버튼 (이용자 목록)

// calculating height
$full-height: calc(var(--vh, 1vh) * 100);
$only_header: calc(#{$full-height} - #{$header-height});
$only_backward_header: calc(#{$full-height} - #{$backward_header_height});
$userlist-height: calc(#{$full-height} - #{$backward-search-floating}); // 이용자목록 케이스
$with-backward-search: calc(#{$full-height} - #{$backwrad-search});
$with-backward-floating: calc(#{$full-height} - #{$backward-floating-height});
$with-floating: calc(#{$full-height} - #{$floating-btn-height});

// modal
$modal-title-height: 56px;
$modal-content-height: calc(#{$full-height} - #{$modal-title-height});
$modal-max-height: calc(#{$full-height} - 80px);
// z-index
$header-z-in: 999;
$toast-z-in: 1000;
$select-z-in: 1000;
// zoom in out container
$zoom-container-outside: $backward_header_height + 40px;
$zoom-container: calc(#{$full-height} - #{$zoom-container-outside});

// media query
$bp-mobile: 475px;
$bp-tablet: 950px;
$bp-desktop: 1024px;

:export {
  bpTablet: $bp-tablet;
}
