.tooltip-wrap {
  position: relative;
  .tooltip-trigger {
    width: 24px;
    height: 24px;
    content: "";
    display: block;
    background: url("../images/icons_info_b.svg") no-repeat;
  }
  .tooltip-floating {
    position: absolute;
    width: 312px;
    padding: 20px;
    top: 34px;
    right: 0;
    z-index: 100;
    background-color: white;
    backdrop-filter: blur(10px);
    box-shadow: 0px 4px 40px 0px #0000001a;
    border-radius: 10px;
    &__contents {
      .tooltip-title {
        font-weight: 600;
        font-size: 15px;
        position: relative;
        &.ic-info {
          padding-left: 28px;
          &::before {
            content: "";
            width: 24px;
            height: 24px;
            background: url("../images/icons_info_b.svg") no-repeat;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }
      .tooltip-desc {
        font-size: 15px;
        margin-top: 10px;
      }
    }
  }
}
.tooltip-trigger {
  display: inline-flex;
  cursor: pointer;
}

.tooltip-content {
  position: absolute;
  width: 320px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  z-index: 1000;
  font-size: 14px;
  line-height: 1.5;
  opacity: 0;
  transform: translateY(-10px);
  animation: tooltip-fade-in 0.5s ease forwards;
  h3 {
    font-size: 15px;
    font-weight: 600;
    position: relative;
    padding-left: 28px;
    &::before {
      content: "";
      width: 24px;
      height: 24px;
      position: absolute;
      left: 0;
      background-image: url("../images/icons_medium_warning.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

.tooltip-content.tooltip-fade-out {
  animation: tooltip-fade-out 0.5s ease forwards;
}

@keyframes tooltip-fade-in {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes tooltip-fade-out {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}

.tooltip-icon {
  width: 24px;
  height: 40px;
  background-image: url("../../assets/images/icons_medium_warning.svg");
  background-repeat: no-repeat;
  background-position: center center;
}
