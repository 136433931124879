.withdrawal-page {
  height: 100%;
  position: relative;
  h2 {
    font-size: 22px;
    font-weight: 600;
  }
  p.desc {
    color: $text-primary3;
    font-size: 15px;
    font-weight: 400;
    padding: 20px 0 30px 0;
    line-height: 22px;
  }
  .btn-wrap {
    width: 100%;
    position: absolute;
    padding: 10px 24px;
    bottom: 0;
    display: flex;
    gap: 10px;
  }
  .complete-layout {
    padding-top: 63px;
  }
  .gray-wrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
    &__box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 52px;
      padding: 0 20px;
      border-radius: 8px;
      background-color: $bg-light;
      & > p {
        font-size: 14px;
        font-weight: 600;
      }
      & > button {
        border: none;
        background-color: transparent;
        font-size: 14px;
        &:disabled {
          color: $text-primary5;
        }
      }
    }
  }
}
