// 메인 배너 스와이퍼
.main-swiper {
  height: 320px;
  .swiper-pagination-bullet {
    background-color: $white !important;
    opacity: 0.3;
    width: 6px;
    height: 6px;
    margin: 0 2.5px !important;
    &.swiper-pagination-bullet-active {
      background-color: $white !important;
      opacity: 1;
    }
  }

  .slider-bg {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .main-slider-box-title {
    font-size: 2.2rem;
    white-space: pre-line;
    color: $white;
    font-weight: 600;
    line-height: 36px; /* 163.636% */
    letter-spacing: -0.88px;
    padding-bottom: 22px;
  }
  .main-slider-box-subtitle {
    font-size: 1.4rem;
    white-space: pre-line;
    color: $white;
    margin-top: 10px;
    margin-bottom: 26px;
    line-height: 20px;
  }
}
// 공간상품 리스트 스와이퍼
.list-swiper {
  height: 240px;
  
  .swiper-pagination-bullet {
    background-color: $white !important;
    opacity: 0.3;
    width: 6px;
    height: 6px;
    margin: 0 2.5px !important;
    &.swiper-pagination-bullet-active {
      background-color: $white !important;
      opacity: 1;
    }
  }
  .swiper-wrapper{
    &::after{
      content:'';
      width:100%;
      height:40px;
      z-index:1;
      position:absolute;
      bottom:0;
      background: linear-gradient(360deg, rgba(25, 25, 25, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
    }
  }
  .slider-bg {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .main-slider-box-title {
    font-size: 3rem;
    white-space: pre-line;
    color: $white;
    line-height: 40px;
  }
  .main-slider-box-subtitle {
    font-size: 1.4rem;
    white-space: pre-line;
    color: $white;
    margin-top: 10px;
    margin-bottom: 26px;
    line-height: 20px;
  }
}

// 공간상품 상세 스와이퍼
.detail-product-swiper {
  height: 300px;
  .swiper-pagination-current {
    margin-right: 4px;
  }
  .swiper-pagination-fraction {
    background: linear-gradient(130deg, #323232 -5.51%, #404040 110.67%);
    border-radius: 30px;
    width: fit-content;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    font-size: 12px;
    color: $white;
    position: absolute;
    bottom: 20px;
    right: 20px !important;
    left: unset;
  }
  .slider-bg {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .main-slider-box-title {
    font-size: 3rem;
    white-space: pre-line;
    color: $white;
    line-height: 40px;
  }
  .main-slider-box-subtitle {
    font-size: 1.4rem;
    white-space: pre-line;
    color: $white;
    margin-top: 10px;
    margin-bottom: 26px;
    line-height: 20px;
  }
}

//공통 스와이퍼 룰
.swiper-slide {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  overflow: hidden;
  padding: 50px 0;
  width: 100%;
}
.swiper-pagination {
  bottom: 20px !important;
}
.swiper-slide .slider-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  padding: 20px 24px;
}
.slider {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  z-index: 0;
  &.noImage {
    background-image: url("../../images/noImage_default.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  &.detail-building {
    height: 300px;
  }
  &.gallery-thumbnail {
    height: 400px;
    .swiper-pagination.swiper-pagination-fraction {
      position: fixed;
      bottom: 30px !important;
      color: $white;
      font-size: 1.4rem;
    }
  }
}

.swiper-container {
  width: 180%;
  float: left;
}
