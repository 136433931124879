.meetingroom {
  height: 100vh;
  position: relative;
  // overflow:hidden;
  &__top-area {
    padding: 20px 24px 40px 24px;

    h1 {
      font-size: 2.4rem;
      letter-spacing: -0.04em;
    }
    background-color: $bg-light;
  }
  &__date-area {
    padding-top: 30px;
    h2 {
      font-size: 1.6rem;
      line-height: 22px;
      font-weight: 600;
      letter-spacing: -0.04em;
    }
    .weekend-wrap {
      padding-top: 20px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      &__day {
        //    margin-right:11px;
        display: flex;
        flex-direction: column;
        align-items: center;
        &:last-child {
          margin-right: none;
        }
        & > span {
          font-size: 1.4rem;
        }
        &-circle {
          margin-top: 6px;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          font-size: 1.5rem;
          border-radius: 50%;
          cursor: pointer;
          span {
            letter-spacing: initial;
          }
          &.--active {
            background-color: $text-primary1;
            color: $white;
          }
        }
      }
    }
  }
  &__table-area {
    padding-left: 24px;
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 24px;
      padding-top: 30px;
      padding-bottom: 26px;
      span {
        font-size: 1.6rem;
        font-weight: 600;
      }
    }
  }
  &__table {
    $left-index-width: 95px; // 좌측 인덱스 넓이;
    overflow: scroll;
    height: calc(100vh - 460px);
    max-height: calc(100vh - 460px);
    -ms-overflow-style: none;
    position: relative;
    border-top: 1px solid #fff;
    // padding-bottom: 160px;
    &::-webkit-scrollbar {
      display: none;
    }
    &-extra {
      width: $left-index-width;
      height: 27px;
      position: sticky;
      z-index: 2;
      left: 0;
      top: 0;
      background-color: #fff;
      border-top:1px solid #fff;
      border-bottom: 1px solid #dbdbdb;
      border-right: 1px solid #dbdbdb;
    }
    &-index-header {
      display: flex;
      position: sticky;
      top: 0;
      background-color: #fff;
      width: fit-content;
      margin-top: -81px;
      z-index: 1;
    }
    &-index-time {
      font-size: 1.4rem;
      width: 80px;
      min-width: 80px;
      height: 27px;
      padding-left: 4px;
      padding-bottom: 9px;
      border-bottom: 1px solid #dbdbdb;
      z-index: 100;
      &:first-child {
        width: $left-index-width;
        min-width: $left-index-width;
        border-left: 1px solid #fff;
      }
      &:nth-child(2) {
        border-left: none;
      }
      &:last-child {
        border-right: none;
      }
    }
    &-vertical-room {
      display: flex;
      height: 116px;
      width: fit-content;
      min-height: 116px;
      max-height: 116px;
      background-color: #fafafa;
      border-bottom: 1px solid #dbdbdb;

      &_index {
        width: $left-index-width;
        background-color: #fff;
        left: 0;
        top: 0;
        position: sticky;
        z-index: 1;
        padding-top: 20px;
        padding-left: 7px;
        border-right: 1px solid #dbdbdb;
        .main-text {
          p {
            font-size: 1.4rem;
            white-space: nowrap;
            line-height: 20px;
          }
        }
        .sub-text {
          padding-top: 2px;
          p {
            font-size: 1.2rem;
            color: $text_primary3;
            &:first-child {
              overflow: hidden;
              width: 100%;
              white-space: nowrap;
              text-overflow: ellipsis;
              margin-bottom: 2px;
              padding-right: 6px;
            }
          }
        }
      }
      &_half {
        width: 40px;
        max-width: 40px;
        min-width: 40px;
        padding: 0;
        border: none;
        background-color: transparent;
        border-left: 1px solid #dbdbdb;
        position: relative;
        &:nth-child(2) {
          border-left: none;
        }
        &.disabled {
          background-image: linear-gradient(
            45deg,
            #dbdbdb 12.5%,
            #fafafa 12.5%,
            #fafafa 50%,
            #dbdbdb 50%,
            #dbdbdb 62.5%,
            #fafafa 62.5%,
            #fafafa 100%
          );
          background-size: 5.66px 5.66px;
        }
        &.disabled ~ .disabled {
          &::before {
            content: "";
            position: absolute;
            width: 80px;
            height: 100%;
            background-image: linear-gradient(
              45deg,
              #dbdbdb 12.5%,
              #fafafa 12.5%,
              #fafafa 50%,
              #dbdbdb 50%,
              #dbdbdb 62.5%,
              #fafafa 62.5%,
              #fafafa 100%
            );
            background-size: 5.66px 5.66px;
            top: 0px;
            left: -40px;
          }
        }
        &.--active {
          background-color: $text_primary1;
        }
        &.--active ~ .--active {
          &::before {
            content: "";
            position: absolute;
            width: 1px;
            height: 100%;
            background-color: $text_primary1;
            top: 0;
            left: -1px;
          }
        }
        // &.merge-disabled::before {
        //   content: "";
        //   position: absolute;
        //   width: 80px;
        //   height: 100%;
        //   background-image: linear-gradient(
        //     45deg,
        //     #dbdbdb 12.5%,
        //     #fafafa 12.5%,
        //     #fafafa 50%,
        //     #dbdbdb 50%,
        //     #dbdbdb 62.5%,
        //     #fafafa 62.5%,
        //     #fafafa 100%
        //   );
        //   background-size: 5.66px 5.66px;
        //   top: 0px;
        //   left: -40px;
        // }
        // &.merge-black::before {
        //   content: "";
        //   position: absolute;
        //   width: 1px;
        //   height: 100%;
        //   background-color: $text_primary1;
        //   top: 0;
        //   left: -1px;
        // }
      }
    }
  }
  .absolute-bottom {
    position: sticky;
    bottom: 0;
    background-color: #fff;
    z-index: 100;
    width: 100%;
    padding: 30px 24px 20px 24px;
    height: 160px;
    border-top: 1px solid #dbdbdb;
    margin-top: -1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .not-selected {
      p {
        font-size: 1.5rem;
        line-height: 22px;
        color: $text-primary3;
      }
    }
  }
  .reservation-btn {
  }
}
