$max: 180;
$max-font: 100;
$minmax-width: 500; //temp

@mixin gap-classes {
  @for $i from 2 through 30 {
    $gap: $i * 1px !important;
    .gap-#{$i} {
      gap: $gap;
    }
  }
}
@include gap-classes;

@mixin margin-classes {
  @for $i from 0 through $max {
    $margin: $i * 1px !important;
    .ma#{$i} {
      margin: $margin;
    }
    .ml#{$i} {
      margin-left: $margin;
    }
    .mr#{$i} {
      margin-right: $margin;
    }
    .mt#{$i} {
      margin-top: $margin;
    }
    .mb#{$i} {
      margin-bottom: $margin;
    }
    .mx#{$i} {
      margin-left: $margin;
      margin-right: $margin;
    }
    .my#{$i} {
      margin-top: $margin;
      margin-bottom: $margin;
    }
  }
}
@include margin-classes;

.mx-auto {
  margin: 0 auto;
}

@mixin padding-classes {
  @for $i from 0 through $max {
    $padding: $i * 1px !important;
    .pa#{$i} {
      padding: $padding;
    }
    .pl#{$i} {
      padding-left: $padding;
    }
    .pr#{$i} {
      padding-right: $padding;
    }
    .pt#{$i} {
      padding-top: $padding;
    }
    .pb#{$i} {
      padding-bottom: $padding;
    }
    .px#{$i} {
      padding-left: $padding;
      padding-right: $padding;
    }
    .py#{$i} {
      padding-top: $padding;
      padding-bottom: $padding;
    }
  }
}
@include padding-classes;

@mixin minmax-classes {
  @for $i from 50 through $minmax-width {
    $value: $i * 1px;
    .minmax#{$i} {
      min-width: $value;
      max-width: $value;
      width: $value;
    }
  }
}
@include minmax-classes;

@mixin font-classes {
  @for $i from 10 through $max-font {
    $value: $i * 0.1rem !important;
    .font#{$i} {
      font-size: $value;
    }
  }
}
@include font-classes;

@mixin font($type: "noto") {
  @if ($type== "noto") {
    font-family: "Noto Sans KR", sans-serif;
  }
  @if ($type== "apple") {
    font-family: "Apple SD Gothic Neo", sans-serif;
  }
  @if ($type== "roboto") {
    font-family: "Roboto", sans-serif;
    letter-spacing: unset;
  }
}

// 버튼 로딩. --loading 클래스와 disabled 속성을 같이 쓴다.

@mixin loading {
  &::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid $white;
    border-top-color: $line_light;
    animation: spinner 0.8s linear infinite;
    inset: 0;
    margin: auto;
  }
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@mixin disabled-btn {
  &:disabled {
    background-color: $line_light;
    color: $white;
    border: none;
    &.--loading {
      span {
        display: none;
      }
    }
  }
}

@mixin ellipsis($line: 1) {
  @if ($line == 1) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  } @else {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $line;
  }
}

@mixin scroll-hidden {
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
}

@mixin mobile {
  @media (max-width: #{$bp-tablet - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$bp-tablet}) {
    @content;
  }
}

@mixin pc-empty-case {
  position: absolute;
  min-height: unset;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
