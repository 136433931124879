@font-face {
  font-family: "Apple SD Gothic Neo";
  src: url("../fonts/AppleSDGothicNeoR.woff2") format("woff2"), url("../fonts/AppleSDGothicNeoR.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Apple SD Gothic Neo";
  src: url("../fonts/AppleSDGothicNeoM.woff2") format("woff2"), url("../fonts/AppleSDGothicNeoM.woff") format("woff");
  font-weight: 500;
  font-style: medium;
}
@font-face {
  font-family: "Apple SD Gothic Neo";
  src: url("../fonts/AppleSDGothicNeoSB.woff2") format("woff2"), url("../fonts/AppleSDGothicNeoSB.woff") format("woff");
  font-weight: 600;
}

@font-face {
  font-family: "Apple SD Gothic Neo";
  src: url("../fonts/AppleSDGothicNeoB.woff2") format("woff2"), url("../fonts/AppleSDGothicNeoB.woff") format("woff");
  font-weight: 700;
  font-style: bold;
}
