$key1: #c99684;
$key2: #809585;
$key3: #9b8885;
$key4: #bfb790;

$system_red: #ff695b;
$system_yellow: #ffd335;
$system_green: #26a687;

$text_primary1: #191919;
$text_primary2: #525252;
$text_primary3: #767676;
$text_primary4: #999999;
$text_primary5: #C0C0C0;

$line_dark: #dbdbdb;
$line_light: #e0e0e0;
$line_gray: #f2f2f2;

$bg_dark: #f2f4f6;
$bg_light: #f8f8fa;

$white: #ffffff;

.text-primary1 {
    color: $text_primary1;
}
.text-primary3 {
    color: $text_primary3 !important;
}
.text-primary4 {
    color: $text_primary4 !important;
}
.text-primary5 {
    color: $text_primary5 !important;
}
.text-red{
    color:$system-red !important;
}
.text-green{
    color:$system-green !important;
}

$grays: (
  "50": #fafbfc,
  "100": #f8f8f8,
  "200": #f1f1f1,
  "300": #e2e2e2,
  "400": #cacaca,
  "500": #acacac,
  "600": #7c7c7c,
  "700": #474747,
  "800": #2c2c2c,
  "900": #242424,
);