.visitor {
  padding: 30px 24px;
  &__logo {
    width: 88px;
    height: 24px;
    object-fit: contain;
    object-position: left;
  }
  &__intro {
    padding-top: 50px;
    h1 {
      font-size: 2.2rem;
      line-height: 32px;
      font-weight: 600;
      letter-spacing: -0.04em;
      margin-bottom: 10px;
    }
    p {
      font-size: 1.5rem;
      line-height: 22px;
      letter-spacing: -0.04em;
    }
  }
  &__info {
    h2 {
      font-size: 1.5rem;
      font-weight: 600;
      letter-spacing: -0.04em;
    }
    .input-wrap {
      .like-disabled-input {
        margin-top: 10px;
        &:first-child {
          margin-top: 20px;
        }
      }
      & ~ .input-wrap {
        padding-top: 10px;
      }
    }
  }
  &__invalid-page {
    width: 100%;
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      padding-top: 30px;
      font-size: 1.8rem;
      font-weight: 600;
      text-align: center;
      line-height: 26px;
    }
  }

  ul {
    li {
      list-style: none;
      font-size: 1.5rem;
      line-height: 20px;
      letter-spacing: -0.04em;
      color: $text-primary1;
      position: relative;
      padding-left: 12px;
      margin-left: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      letter-spacing: -0.04px;
      &:last-child {
        margin-bottom: 0;
      }
      &:before {
        content: "";
        width: 3px;
        height: 3px;
        border-radius: 8px;
        background-color: $text_primary1;
        position: absolute;
        left: 0;
        top: 9px;
      }
    }
  }
  .base-markdown {
    .toastui-editor-contents {
      ul li {
        margin: 0;
        padding-left: 0;
      }
    }
  }
}

.qr-container {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  &__container {
    width: 220px;
    height: 220px;
    margin-bottom: 20px;
    position: relative;
  }
  &__wrap {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    position: relative;
  }
  .main-text {
    font-weight: 600;
    font-size: 1.4rem;
    color: $text-primary1;
    margin-bottom: 4px;
  }
  .sub-text {
    color: $text-primary3;
    font-size: 1.4rem;
  }
  .qr-text {
    margin-top: -20px;
    font-size: 1.4rem;
    font-weight: 700;
  }
  .waiting-qr {
    width: 200px;
    height: 200px;
    background-image: url("../../images/sample_qr.png");
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    > div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $white;
      width: 220px;
      height: 40px;
      display: flex;
      align-items: center;
      p {
        width: 100%;
        font-size: 1.5rem;
        color: $text-primary3;
        text-align: center;
      }
    }
    button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $text-primary1;
      color: $white;
      border: none;
      font-size: 1.5rem;
      padding-left: 27px;
      width: 136px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 12px;
        width: 24px;
        height: 24px;
        background-image: url("../../images/icons_refresh_w.svg");
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
    &.invitation {
      p {
        font-size: 1.4rem;
        color: $text-primary1;
        white-space: nowrap;
        text-align: center;
        position: absolute;
        left: 50%;
        top: 50%;
        background-color: $white;
        padding: 10px 0;
        transform: translateX(-50%) translateY(-50%);
      }
    }
  }
}
.error-img {
  width: 62px;
  height: 85px;
  background: url("../../images/icons_underline_question.svg") no-repeat center;
}
.frame-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  --border-space: 12px;
  $border-width: 1px;
  .frame-element {
    position: absolute;
    width: 5px;
    height: 5px;
  }
  .top-left {
    border-left: $border-width solid $text_primary1;
    border-top: $border-width solid $text_primary1;
    top: 0px;
    left: 0px;
  }
  .top-right {
    border-right: $border-width solid $text_primary1;
    border-top: $border-width solid $text_primary1;
    top: 0px;
    right: 0px;
  }
  .bottom-left {
    border-left: $border-width solid $text_primary1;
    border-bottom: $border-width solid $text_primary1;
    bottom: 0px;
    left: 0px;
  }
  .bottom-right {
    border-right: $border-width solid $text_primary1;
    border-bottom: $border-width solid $text_primary1;
    bottom: 0px;
    right: 0px;
  }
}
.map-container {
  width: 100%;
  max-width: 100%;
  height: 272px;
  max-height: 272px;
}

.visitor-form {
  .base-btn {
    font-size: 1.8rem;
    height: 58px;
  }
  &__logo {
    width: 100%;
    height: 43px;
    display: flex;
    align-items: center;
    padding: 0 24px;
    .dears_pangyo {
      width: 96px;
      height: 20px;
      background-image: url("../../images/logo_dears_pangyo.png");
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  &__intro {
    padding: 20px 24px;
    h1 {
      font-size: 2.6rem;
      line-height: 39px;
      font-weight: 700;
      letter-spacing: -0.04em;
      margin-bottom: 10px;
      color: $text-primary1;
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: $text_primary3;
    }
  }
  &__form {
    padding: 30px 24px;
    .select-div {
      width: 100%;
      height: 54px;
      border-bottom: 1px solid $line-dark;
      position: relative;
      display: flex;
      align-items: center;
      font-size: 1.8rem;
      color: $text-primary4;
      cursor: pointer;
      &::after {
        content: "";
        width: 26px;
        height: 26px;
        background-image: url("../../images/icons_arrow_down.svg");
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .desc {
      font-size: 1.6rem;
      text-align: center;
    }
    .base-input-wrap {
      .base-input {
        font-size: 1.8rem;
        &::placeholder {
          font-size: 1.8rem;
          letter-spacing: -0.04rem;
        }
      }
      .clear-btn {
        top: 8px;
        right: -7px;
      }
      .check-green-btn {
        top: 8px;
        right: -7px;
      }
    }
  }
}

.visitor-complete {
  &__logo {
    width: 100%;
    height: 43px;
    display: flex;
    align-items: center;
    padding: 0 24px;
    .dears_pangyo {
      width: 96px;
      height: 20px;
      background-image: url("../../images/logo_dears_pangyo.png");
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  &__intro {
    padding: 20px 24px;
    h1 {
      font-size: 2.4rem;
      line-height: 39px;
      font-weight: 700;
      letter-spacing: -0.04em;
      margin-bottom: 10px;
      color: $text-primary1;
    }
    p {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 22px;
      color: $text_primary3;
    }
  }
  &__contents {
    background: linear-gradient(180deg, rgba(248, 248, 250, 0) 69.97%, #f8f8fa 100%);
    .desc {
      font-size: 1.6rem;
      margin-top: 30px;
      margin-bottom: 20px;
      text-align: center;
    }
    .alert-talk-img {
      margin: 0 auto;
      width: 260px;
      height: 350px;
      background-image: url("../../images/img_talk.png");
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  &__info {
    padding: 30px 24px;
    p {
      font-size: 1.6rem;
      margin-bottom: 14px;
    }
    ul:not(.base-list) {
      // padding-top:20px;
      li {
        list-style: none;
        font-size: 1.4rem;
        line-height: 22px;
        letter-spacing: -0.04em;
        color: $text-primary3;
        position: relative;
        padding-left: 12px;
        margin-left: 6px;
        &:before {
          content: "·";
          position: absolute;
          left: 0;
          font-size: 20px;
          vertical-align: middle;
          line-height: 20px;
        }
      }
    }
  }
  .base-btn {
    font-size: 1.8rem;
  }
}

.parking-number-box {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $bg_light;
  padding: 0 20px;
  border-radius: 4px;
  .car-number {
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 20px;
    font-family: "Roboto";
  }
}

.change-car-number {
  padding: 20px 24px;
  .info-area {
    h2 {
      font-size: 22px;
      font-weight: 600;
      letter-spacing: -0.04px;
    }
  }
}
