.--loading {
  position: relative;
  &::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid $white;
    border-top-color: $line_light;
    animation: spinner 0.8s linear infinite;
  }
}

.loader {
  --d: 22px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  color: #808080;
  box-shadow: calc(1 * var(--d)) calc(0 * var(--d)) 0 0.1px rgba(128, 128, 128, 0.025),
    calc(0.866 * var(--d)) calc(0.5 * var(--d)) 0 0.2px rgba(128, 128, 128, 0.05),
    calc(0.5 * var(--d)) calc(0.866 * var(--d)) 0 0.3px rgba(128, 128, 128, 0.1),
    calc(0 * var(--d)) calc(1 * var(--d)) 0 0.4px rgba(128, 128, 128, 0.2),
    calc(-0.5 * var(--d)) calc(0.866 * var(--d)) 0 0.6px rgba(128, 128, 128, 0.3),
    calc(-0.866 * var(--d)) calc(0.5 * var(--d)) 0 0.8px rgba(128, 128, 128, 0.4),
    calc(-1 * var(--d)) calc(0 * var(--d)) 0 1px rgba(128, 128, 128, 0.5),
    calc(-0.866 * var(--d)) calc(-0.5 * var(--d)) 0 1.2px rgba(128, 128, 128, 0.6),
    calc(-0.5 * var(--d)) calc(-0.866 * var(--d)) 0 1.4px rgba(128, 128, 128, 0.7),
    calc(0 * var(--d)) calc(-1 * var(--d)) 0 1.6px rgba(128, 128, 128, 0.8),
    calc(0.5 * var(--d)) calc(-0.866 * var(--d)) 0 1.8px rgba(128, 128, 128, 0.9),
    calc(0.866 * var(--d)) calc(-0.5 * var(--d)) 0 2px rgba(128, 128, 128, 1);
  animation: l27 1s infinite steps(12);
  margin: 0 auto;
}

@keyframes l27 {
  100% {
    transform: rotate(1turn);
  }
}
