footer.footer-mo {
  background-color: $bg_dark;
  div.box {
    width: 100%;
    padding: 32px 24px;
    border-bottom: 1px solid $line_light;
    display: flex;
    align-items: center;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    row-gap: 10px;
    align-items: center;
    list-style: none;

    li {
      margin-right: 20px;
      font-size: 1.4rem;
      position: relative;
      white-space: nowrap;
      &::after {
        content: "";
        position: absolute;
        height: 11px;
        width: 1px;
        background-color: $line_dark;
        top: calc(50% + 1px);
        transform: translateY(-50%);
        right: -10px;
      }
      &:last-child {
        &::after {
          display: none;
        }
        margin-right: 0;
      }
    }
  }
  section {
    padding: 30px 24px;
    button.company-toggle {
      font-size: 1.4rem;
      font-weight: 600;
      color: $text-primary1;
      border: none;
      position: relative;
      background-color: transparent;
      padding-right: 29px;

      cursor: pointer;
      &::after {
        content: "";
        position: absolute;
        width: 24px;
        height: 24px;
        top: calc(50% - 1px);
        right: 0;
        transform: translateY(-50%);
      }
      &.icon-up::after {
        background: url("../../images/icons_chevron_down.svg") center no-repeat;
      }
      &.icon-down::after {
        background: url("../../images/icons_chevron_up.svg") center no-repeat;
        transform: translateY(-50%);
      }
    }
    .company-info {
      color: $text-primary3;
      font-size: 1.4rem;
      padding-top: 18px;
      padding-bottom: 20px;
      line-height: 20px;
    }
  }
}

footer.footer-pc {
  width: 100%;
  background-color: transparent;
  margin-top: 100px;
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid $line_dark;
  ul {
    list-style: none;
    text-align: center;
    &.main-list {
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
      li {
        font-size: 1.4rem;
        color: $text-primary1;
        margin-right: 20px;
        position: relative;
        &:after {
          content: "|";
          position: absolute;
          color: $line_dark;
          right: -11px;
        }
        &:last-child {
          margin-right: 0;
          &:after {
            content: unset;
          }
        }
      }
    }
    &.info-list {
      margin-bottom: 10px;
      li {
        font-size: 1.2rem;
        color: $text-primary3;
        margin-bottom: 4px;
        &:last-child {
          margin-top: 10px;
        }
      }
    }
  }
  display: none;
}
