.contract-page {
  .contract-main {
    padding: 20px 24px 30px 24px;
    h1 {
      font-size: 2.4rem;
      line-height: 34px;
      letter-spacing: -0.04em;
      padding-bottom: 50px;
      &.message {
        font-size: 2.2rem;
        font-weight: 600;
        padding-bottom: 10px;
      }
    }
    &__index-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      p {
        font-size: 1.6rem;
        font-weight: 600;
      }
    }
    .application-number {
      font-size: 1.4rem;
      color: $text-primary3;
    }
    .desc {
      font-size: 1.5rem;
      padding-top: 50px;
      padding-bottom: 30px;
      line-height: 22px;
      // border-bottom: 1px solid $line_dark;
    }
    .gray-card {
      width: 100%;
      background-color: $bg_light;
      border-radius: 8px;
      width: 100%;
      height: auto;
      padding: 20px;
      // &::after {
      //   content: "";
      //   width: 24px;
      //   height: 24px;
      //   position: absolute;
      //   background-image: url("../../images/icons_chevron_right.svg");
      //   right: 10px;
      //   opacity: 0.5;
      // }
      img {
        width: 60px;
        height: 60px;
        object-fit: cover;
        border-radius: 4px;
        margin-right: 20px;
      }
      &__product {
        display: flex;
        align-items: center;
        position: relative;
      }
      &__info {
        margin-top: 20px;
        border-top: 1px solid $line_light;
        padding-top: 20px;
      }
      &__contents {
        width: 100%;
        overflow: hidden;
        &-title {
          width: 100%;
          font-size: 1.5rem;
          line-height: 22px;
          font-weight: 600;
          color: $text-primary1;
          // padding-right: 24px;
          margin-bottom: 5px;
          @include ellipsis(1);
        }
        &-address {
          font-size: 1.2rem;
          line-height: 16px;
          color: $text-primary3;
        }
      }
    }
    .base-section-layout__container {
      padding-top: 0;
    }
  }
  .contract-price {
    padding: 30px 24px;
    &.contract-detail {
      padding: 34px 24px;
    }
    h2 {
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 22px;
      padding-bottom: 20px;
    }
    &__title-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 20px;
      h2 {
        padding-bottom: 0px;
      }
    }
    &__wrap {
      & ~ .contract-price__wrap {
        border-top: 1px solid $line_dark;
        padding-top: 30px;
        margin-top: 30px;
      }
    }
    &-bottom-line {
      border-bottom: 1px solid $line_dark;
      padding-bottom: 30px;
      margin-bottom: 30px;
    }
    &-line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      white-space: nowrap;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &-index {
      font-size: 1.5rem;
      line-height: 22px;
      .desc {
        font-size: 1.2rem;
        margin-left: 5px;
      }
    }
    &-value {
      font-size: 1.5rem;
      line-height: 22px;
      font-family: "Roboto", sans-serif;
    }
  }
  .contract-period {
    padding: 30px 24px;
    h2 {
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 22px;
      padding-bottom: 20px;
    }
  }
  .contract-caution {
    padding: 30px 24px;
    h2 {
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 22px;
      padding-bottom: 20px;
    }
    .btn-wrap {
      // margin-top: 80px;
      // margin-bottom: 50px;
      display: flex;
      align-items: center;
      padding: 80px 0px 70px 0px;
    }
  }
  .contract-application {
    padding: 30px 24px;
    h2 {
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 22px;
      padding-bottom: 20px;
    }
    &__wrap {
      & ~ .contract-application__wrap {
        border-top: 1px solid $line_dark;
        padding-top: 30px;
        margin-top: 30px;
      }
    }
    &-bottom-line {
      border-bottom: 1px solid $line_dark;
      padding-bottom: 30px;
      margin-bottom: 30px;
    }
    &-line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      white-space: nowrap;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &-index {
      font-size: 1.5rem;
      line-height: 22px;
      .desc {
        font-size: 1.2rem;
        margin-left: 5px;
      }
    }
    &-value {
      font-size: 1.5rem;
      line-height: 22px;
      font-family: "Roboto", sans-serif;
    }
  }
  .btn-wrap {
    padding: 80px 24px 50px 24px;
  }
  // ul {
  //   li {
  //     list-style: none;
  //     font-size: 1.4rem;
  //     line-height: 20px;
  //     letter-spacing: -0.04em;
  //     color: $text-primary3;
  //     position: relative;
  //     padding-left: 12px;
  //     &:before {
  //       content: "·";
  //       position: absolute;
  //       left: 0;
  //       font-size: 20px;
  //       vertical-align: middle;
  //       line-height: 20px;
  //     }
  //   }
  // }
  .contract-history {
    padding: 20px 24px;
    h1 {
      font-size: 2.4rem;
    }
    .contents-area {
      width: 100%;
      .contents-card {
        width: 100%;
        min-width: 310px;
        height: auto;
        padding: 20px 20px 28px 20px;
        background-color: $bg_light;
        border-radius: 8px;
        margin-bottom: 13px;
        // &:last-child{
        //   margin-right:24px;
        // }
        &__title-area {
          // display:flex;
          // align-items: center;
          // justify-content: space-between;
          p {
            font-size: 1.5rem;
            font-weight: 600;
            position: relative;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding-right: 10px;
          }
          button {
            width: 24px;
            height: 24px;
            border: none;
            background: url("../../images/icons_chevron_right.svg") no-repeat center;
            opacity: 0.5;
          }
        }
        &__date {
          padding-top: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          min-height: 36px;
          span {
            color: $text-primary3;
            font-size: 1.4rem;
            font-weight: 400;
            @include font("roboto");
          }
        }
        &__info-area {
          padding-top: 10px;
          margin-top: 16px;
          min-height: 50px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-top: 1px solid $line_light;
          p {
            font-size: 1.4rem;
            color: $text-primary3;
            line-height: 20px;
          }
          button {
            width: 120px;
            height: 40px;
            font-size: 1.5rem;
            font-weight: 600;
            line-height: 22px;
            border: 1px solid $line-dark;
            background-color: $white;
            border-radius: 4px;
          }
        }
      }
    }
    .contents-empty {
      min-height: $only_backward_header;
    }
  }
  .contract-history-confirmation {
    .contents-empty {
      min-height: calc(#{$full-height} - 95px);
    }
  }
  .contract-history-montly {
    .contract-main {
      padding-bottom: 0;
    }
    .payment-schedule-list {
      .layout-wrap {
        padding: 20px 0;
      }
      .layout-line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .layout-index {
          font-size: 1.5rem;
          line-height: 22px;
          white-space: nowrap;
        }
        .layout-value {
          font-size: 1.5rem;
          line-height: 22px;
          letter-spacing: unset;
          font-family: "Roboto", sans-serif;
          text-align: right;
        }
      }
    }
  }
  .contract-select-building {
    width: 100%;
    overflow: hidden;
    padding-top: 30px;
    padding-left: 24px;
    padding-right: 24px;
    &__dropdown {
      width: 390px;
      height: auto;
      position: absolute;
      z-index: 1;
      top: 30px;
      background-color: $white;
      box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.08);
      border-radius: 8px;
      padding: 30px 24px;
      h2 {
        font-size: 1.6rem;
        font-weight: 600;
      }
      .building-list {
        &__item {
          padding-top: 30px;
          display: flex;
          align-items: center;
          cursor: pointer;
          // justify-content: space-between;
        }
        &__item-wrap {
          display: flex;
          align-items: center;
          width: 100%;
        }
        &__item-info {
          width: 100%;
          max-width: 100%;
          overflow: hidden;
          p {
            font-size: 1.5rem;
            font-weight: 400;
            @include ellipsis(2);
          }
        }
        &__item-default {
          width: 24px;
          min-width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 15px;
        }
        &__item-checked {
          margin-left: 15px;
          width: 24px;
          height: 24px;
          background-image: url("../../images/icons_check.svg");
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }
}
