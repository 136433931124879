.space-info {
  padding: 20px 24px 20px 24px;
  overflow-y: auto;
  height: $only_backward_header;
  @include scroll-hidden;
  .title-wrap {
    h1 {
      font-size: 2.4rem;
      line-height: 32px;
      font-weight: 700;
      margin-top: 4px;
      margin-bottom: 30px;
    }
    h2 {
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 2.2rem;
      @include ellipsis(2);
    }
    span {
      font-size: 1.2rem;
      font-weight: 600;
    }
    p {
      font-size: 1.4rem;
      line-height: 2rem;
      color: $text-primary3;
      letter-spacing: -0.04em;
      padding-top: 10px;
    }
    .chevron-down-btn.--active::after {
      transform: translateY(-50%) rotate(270deg);
    }
    .chevron-down-btn {
      p.ellipsis {
        width: 100%;
        font-size: 1.6rem;
        line-height: 22px;
        font-weight: 600;
        color: $text-primary1;
        padding: unset;
      }
    }
    .support-banner {
      width: 100%;
      background-color: $key1;
      height: 60px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      padding: 0 20px;
      margin-bottom: 20px;
      justify-content: space-between;
      position: relative;
      cursor: pointer;
      p {
        padding-top: 0;
        color: white;
        &:first-child {
          font-size: 1.5rem;
          font-weight: 600;
          padding-left: 40px;
          &::before {
            content: "";
            width: 30px;
            height: 30px;
            position: absolute;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
            background-image: url("../../images/icons_alert_white.svg");
          }
        }
        &:last-child {
          font-size: 1.4rem;
          padding-right: 18px;
          &::after {
            content: "";
            width: 14px;
            height: 14px;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            background-image: url("../../images/icons_small_arrow_white.svg");
          }
        }
      }
    }
    .voc {
      background-color: $key1;
      border-radius: 8px;

      cursor: pointer;

      display: flex;
      align-items: center;

      padding: 15px 20px;

      font-size: 15px;

      color: white;

      margin-bottom: 43px;

      .ic-icons-alert-white {
        background-image: url("../../images/icons_alert_white.svg");

        width: 30px;
        height: 30px;

        min-width: 30px;

        margin-right: 10px;
      }

      .voc-text {
        display: flex;
        align-items: center;
        width: 100%;

        justify-content: space-between;
      }
    }
  }
  &.detail {
    .title-wrap {
      p {
        font-size: 1.4rem;
        line-height: 2rem;
        color: $text-primary4;
        letter-spacing: -0.04em;
        padding-top: 10px;
        padding-bottom: 20px;
        @include ellipsis;
      }
    }
  }
  .contents {
    //오리진 추후삭제
    &__item {
      width: 100%;
      border-top: 1px solid $line_light;

      &.--active {
        .contents__item-content {
          max-height: 2000px;
          transition: max-height 0.25s ease-in;
        }
        .contents__item-header::after {
          transform: rotate(90deg);
        }
      }
      &.--default {
        .contents__item-content {
          max-height: 0;
          transition: max-height 0.25s ease-in;
        }
      }
      &.--inactive {
        .contents__item-content {
          max-height: unset;
          transition: max-height 0.25s ease-in;
        }
        .contents__item-header::after {
          transform: rotate(90deg);
        }
      }
      &:last-child {
        border-bottom: 1px solid $line_light;
      }
      &-header {
        display: flex;
        align-items: center;
        width: 100%;
        height: 70px;
        padding: 20px 0;
        position: relative;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        &::after {
          content: "";
          width: 20px;
          height: 20px;
          right: 0;
          position: absolute;
          background: url("../../images/icons_chevron_right.svg") no-repeat right;
          background-size: 20px;
        }
      }
      p {
        font-size: 1.5rem;
        white-space: pre-line;
      }
      &-icon {
        width: 30px;
        height: 30px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        margin-right: 20px;
        &.ic-wifi {
          background-image: url("../../images/icons_large_wifi.svg");
        }
        &.ic-print {
          background-image: url("../../images/icons_large_print.svg");
        }
        &.ic-parking {
          background-image: url("../../images/icons_large_parking.svg");
        }
        &.ic-etc {
          background-image: url("../../images/icons_large_etc.svg");
        }
      }
      &-content {
        // max-height: 0;
        transition: max-height 0.25s ease-out;
        overflow: hidden;
        &.--inactive {
          max-height: unset;
        }
        .wifi-info {
          padding-top: 10px;
          padding-bottom: 10px;
          h2 {
            font-size: 1.6rem;
            font-weight: 600;
            line-height: 22px;
          }
          &__wrap {
            padding: 30px 0;
            border-bottom: 1px solid $line_light;
            &:last-child {
              border-bottom: none;
            }
            h3 {
              font-size: 1.5rem;
              font-weight: 600;
              line-height: 22px;
              margin-bottom: 10px;
            }
            .input-wrap {
              padding-top: 10px;
              padding-bottom: 20px;
              .like-disabled-input {
                margin-bottom: 10px;
                &:last-child {
                  margin: 0;
                }
              }
            }
          }
        }
        .print-info {
          h2 {
            font-size: 1.6rem;
            font-weight: 600;
            line-height: 22px;
            margin-bottom: 30px;
          }
          &__wrap {
            padding: 30px 0;
            border-bottom: 1px solid $line_light;
            &:last-child {
              border-bottom: none;
            }
            &:first-child {
              padding-top: 6px;
            }
            h3 {
              font-size: 1.5rem;
              font-weight: 600;
              line-height: 22px;
              margin-bottom: 10px;
            }
            p {
              line-height: 20px;
              white-space: pre-line;
            }
            .input-wrap {
              padding-top: 10px;
              padding-bottom: 20px;
              .like-disabled-input {
                margin-bottom: 10px;
                &:last-child {
                  margin: 0;
                }
              }
            }
          }
          ul {
            padding-bottom: 10px;
            li {
              list-style: none;
              font-size: 1.5rem;
              line-height: 22px;
              letter-spacing: -0.04em;
              color: $text-primary1;
              padding-left: 14px;
              position: relative;
              margin-bottom: 22px;
              &:before {
                content: "-";
                position: absolute;
                left: 0;
                font-weight: 400;
                font-size: 15px;
                vertical-align: middle;
                line-height: 20px;
              }
            }
          }
        }
        .parking-info {
          &__wrap {
            padding: 30px 0;

            &:first-child {
              padding-top: 6px;
            }
            .input-wrap {
              .like-disabled-input {
                margin-bottom: 10px;
                &:last-child {
                  margin: 0;
                }
              }
            }
          }
        }
        .extra-info {
          padding-bottom: 10px;
          &__wrap {
            padding: 30px 0;

            &:first-child {
              padding-top: 6px;
            }
          }
          p {
            font-size: 1.5rem;
            line-height: 22px;
            white-space: pre-line;
          }
        }
      }
    }
  }

  p.desc {
    font-size: 1.4rem;
    line-height: 20px;
    color: #767676;
    letter-spacing: -0.04em;
    word-break: break-all;
    white-space: pre-line;
  }
  .not-provide {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-top: 18px;
    padding-bottom: 50px;
    img {
      width: 62px;
      height: 85px;
    }
    p {
      padding-top: 20px;
    }
  }
  .contents {
    &__section {
      padding-top: 20px;
      border-top: 1px solid $line_dark;
      font-size: 1.4rem;
      white-space: pre-line;
    }
  }
  .contents-empty {
    min-height: calc(calc(var(--vh, 1vh) * 100) - 218px);
    p {
      font-weight: 400;
    }
    .icon {
      width: 62px;
      height: 85px;
      margin: 0 auto;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      &.ic-none {
        background-image: url("../../images/icons_underline_none.svg");
      }
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.seat-chart {
  iframe {
    width: 100%;
    height: 65vh;
    background-color: #767676;
    border: none;
  }
  .scroll-container {
    height: 65vh;
    width: 100%;
    overflow: scroll;
  }
}

.seat-container-zoom {
  // border: 1px solid #e0e0e0;
  height: $full_height;
  position: relative;
  overflow: hidden;
  // @include scroll-hidden;
  // img {
  //   opacity: 0.8;
  // }
  &.with-header {
    height: $only_backward_header;
  }
  &.border-gray {
    box-shadow: 0 0 0 1px $line_dark inset;
  }

  .seat-container {
    position: relative;
    top: 0;
    left: 0;
    transform-origin: left top;
  }
  img {
    display: block;
  }
  .snack {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .ic-snack {
      width: 57px;
      height: 57px;
      margin-bottom: 30px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("../../images/icons_large_snack.svg");
    }
    h2 {
      font-size: 1.8rem;
      font-weight: 600;
      padding-bottom: 10px;
    }
    p {
      font-size: 1.5rem;
    }
  }
}
.seat-legend {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
  font-size: 1.4rem;
  &__default {
    display: flex;
    padding-left: 24px;
    position: relative;
    margin-right: 20px;
    &:before {
      position: absolute;
      content: "";
      left: 0px;
      top: 50%;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
      border: 1px solid $line_light;
    }
  }
  &__disabled {
    display: flex;
    padding-left: 24px;
    position: relative;
    &:before {
      position: absolute;
      content: "";
      background-color: $line_light;
      left: 0px;
      top: 50%;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
      border: 1px solid $line_light;
    }
  }
}
.react-transform-wrapper {
  max-width: 100%;
  max-height: unset;
  height: 100% !important;
  .desc-comp {
    width: 30px;
    height: 24px;
    font-size: 1.1rem;
    border: 1px solid $line_light;
    background-color: $white;
    color: $text-primary1;
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    &.--disabled {
      background-color: $line_light;
      color: $white;
    }
  }
  .seat-container {
    position: relative;
    top: 0;
    left: 0;
    transform-origin: left top;
  }
}
// 일단 공통으로 전환
.list-section {
  &__item {
    width: 100%;
    border-top: 1px solid $line_light;
    padding: 20px 0;

    &:last-child {
      border-bottom: 1px solid $line_light;
    }
    p {
      font-size: 1.6rem;
      white-space: pre-line;
      cursor: pointer;
    }

    .title {
      display: flex;
      position: relative;
      width: fit-content;

      .red-dot {
        position: absolute;
        top: 2px;
        right: -8px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: red;
      }
    }
  }
}
