.file-viewer-pdf {
  width: 100% !important;
  max-width: 1205px !important;
  height: 100% !important;
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
  // border: 1px solid black;
  position: relative;
  // min-width: 614px;
  &__document {
    width: 100% !important;
    max-width: 475px !important;
    height: 100% !important;
    overflow: scroll;
    @include scroll-hidden;
  }

  .react-pdf__Page__canvas {
    width: 100% !important;
    max-width: 475px !important;
    height: 100% !important;
    overflow: scroll !important;
    @include scroll-hidden;
  }

  .react-pdf__Page__annotations.annotationLayer {
    display: none;
  }
  .react-pdf__message.react-pdf__message--loading {
    display: none;
  }
  .button-wrap {
    button {
      z-index: 999;
      position: absolute;
      width: 30px;
      height: 30px;
      &:disabled {
        opacity: 0.3;
      }
      &.left {
        left: 20px;
        top: 50%;
        transform: translateY(-50%) rotate(90deg);
        background: url("../../images/ic_chevron_down_b.svg");
        background-repeat: no-repeat;
        background-size: cover;
        border: none;
      }
      &.right {
        right: 20px;
        top: 50%;
        transform: translateY(-50%) rotate(-90deg);
        background: url("../../images/ic_chevron_down_b.svg");
        background-repeat: no-repeat;
        background-size: cover;
        border: none;
      }
    }
  }
}
