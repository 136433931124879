.my-page {
  .before-login-contents {
    padding: 30px 24px;
    h1 {
      font-size: 2.4rem;
      margin-bottom: 10px;
    }
    p {
      font-size: 1.4rem;
      color: $text-primary3;
      line-height: 20px;
      margin-bottom: 80px;
    }
  }
  .after-login-contents {
    padding: 30px 24px;
    h1 {
      font-size: 2.4rem;
      margin-bottom: 36px;
    }

    .profile-img {
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $key1;
      border-radius: 50%;
      span {
        font-size: 2.4rem;
        color: $white;
      }
    }
    .profile-content {
      padding-left: 10px;
      p {
        font-size: 1.6rem;
        position: relative;
        &.invalid::after {
          content: "";
          position: absolute;
          width: 14px;
          height: 14px;
          top: calc(50% - 1px);
          transform: translateY(-50%);
          right: -20px;
          background: url("../../images/icons_error.svg") no-repeat center;
        }
      }
      button {
        background-color: transparent;
        border: none;
        color: $text-primary3;
        font-size: 1.4rem;
        margin-top: 2px;
      }
    }
  }
  .after-login-contract {
    padding: 30px 0px 30px 24px;
    .title-area {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 24px;
      span {
        font-size: 1.6rem;
        font-weight: 600;
      }
      button {
        font-size: 1.4rem;
        background-color: transparent;
        border: none;
        padding-right: 24px;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          width: 24px;
          height: 24px;
          top: calc(50% - 1px);
          background: url("../../images/icons_chevron_right.svg") no-repeat center;
          transform: translateY(-50%);
        }
      }
    }
    .contents-area {
      padding-top: 20px;
      overflow-x: scroll;
      width: 100%;
      display: flex;
      align-items: center;
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
      }
      .contents-card {
        // width: 310px;
        min-width: 310px;
        height: 134px;
        padding: 20px;
        background-color: $bg_light;
        border-radius: 8px;
        margin-right: 10px;
        &:last-child {
          margin-right: 24px;
        }
        &__title-area {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 16px;
          border-bottom: 1px solid $line_dark;
          p {
            font-size: 1.5rem;
            font-weight: 600;
            position: relative;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding-right: 10px;
          }
        }
        &__info-area {
          padding-top: 15px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          p {
            font-size: 1.4rem;
            color: $text-primary3;
            line-height: 20px;
          }
          button {
            width: 24px;
            height: 24px;
            border: none;
            background: url("../../images/icons_chevron_right.svg") no-repeat center;
            opacity: 0.5;
          }
        }
      }
      &.no-list {
        padding-right: 24px;
      }
      .contents-card2 {
        min-width: 310px;
        height: 100px;
        padding: 20px;
        background-color: $bg_light;
        border-radius: 8px;
        margin-right: 10px;
        &:last-child {
          margin-right: 24px;
        }
        &__title-area {
          display: flex;
          align-items: center;
          // justify-content: space-between;
          img {
            width: 60px;
            height: 60px;
            object-fit: cover;
            border-radius: 4px;
            margin-right: 20px;
          }
          .two_line_ellipsis {
            font-size: 1.5rem;
            font-weight: 600;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          p {
            font-size: 1.5rem;
            font-weight: 600;
            position: relative;
            overflow: hidden;
            white-space: nowrap;
            word-wrap: break-all;
            text-overflow: ellipsis;
            padding-right: 10px;
          }
        }
      }
      .contents-card-none {
        width: 100%;
        height: 100px;
        background-color: $bg_light;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          font-size: 1.5rem;
          font-weight: 600;
          line-height: 22px;
          color: $text-primary1;
        }
      }
    }
  }
  .before-login-list {
    & > ul {
      li {
        height: 62px;
        list-style: none;
        padding: 0 24px;
        font-size: 1.5rem;
        position: relative;
        div {
          padding: 22px 24px;
          display: inline-block;
          align-items: center;
          justify-content: inherit;
          padding-left: 34px;
          p {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
          }
        }

        a {
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          padding-left: 34px;
        }
        &.icon::before {
          content: "";
          position: absolute;
          background-color: red;
          width: 24px;
          height: 24px;
          left: 24px;
          top: 50%;
          transform: translateY(-50%);
        }
        &.icon-notice::before {
          background: url("../../images/icons_large_bell.svg") no-repeat;
        }
        &.icon-call::before {
          background: url("../../images/icons_large_call.svg") no-repeat;
        }
        &.icon-product::before {
          background: url("../../images/icons_large_product.svg") no-repeat;
        }
      }
    }
  }
  .container {
    padding: 30px 24px;

    h5 {
      font-size: 22px;
      line-height: 32px;
      font-weight: 600;
      margin-top: 0;
    }
    p.description {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: #767676;
      margin-top: 16px;
      margin-bottom: 26px;
    }
    p.agree {
      margin-top: 82px;
      margin-bottom: 8px;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: -0.04em;
      text-align: left;
      color: $text-primary3;
      a {
        color: $text-primary3;
        text-decoration: underline;
      }
    }
  }
  .email-auth {
    padding: 30px 24px;
    .title-wrap {
      margin-bottom: 50px;
      h1 {
        font-size: 2.2rem;
        line-height: 32px;
        font-weight: 600;
      }
      p {
        font-size: 1.4rem;
        line-height: 2rem;
        color: $text-primary3;
        letter-spacing: -0.04em;
        padding-top: 10px;
      }
    }
    .contents {
      text-align: center;
      margin-bottom: 80px;
      img {
        width: 62px;
        margin-bottom: 30px;
      }
      p {
        font-size: 1.4rem;
      }
    }
    .btn-wrap {
      padding-top: 80px;
    }
  }
  .profile-edit {
    width: 100%;
    height: $only-header;
    padding: 30px 24px 20px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h1 {
      font-size: 2.4rem;
    }
    .profile-img {
      width: 90px;
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $key1;
      border-radius: 50%;
      span {
        font-size: 3.2rem;
        color: $white;
      }
    }
    .marketing-text {
      white-space: nowrap;
      span {
        font-weight: 600;
        font-size: 1.5rem;
        color: $text-primary1;
      }
      p {
        font-size: 1.4rem;
        color: $text-primary3;
        margin-top: 4px;
      }
    }
    &__bottom {
      display: flex;
      justify-content: center;
      ul {
        display: flex;
        list-style: none;
        li {
          position: relative;
          button {
            color: $text-primary3;
            font-size: 1.5rem;
            line-height: 22px;
            border: none;
            background-color: transparent;
          }
          &:last-child {
            padding-left: 20px;
            &::before {
              content: "|";
              position: absolute;
              left: 9px;
              font-size: 1.5rem;
              line-height: 22px;
              color: $line_light;
            }
          }
        }
      }
    }
  }
  &-cashbill {
    .identity-type {
      .react-select__placeholder {
        text-align: left;
      }
      .react-select__single-value {
        text-align: left;
      }
    }
  }
}

.arrow-right {
  width: 24px;
  height: 24px;
  border: none;
  background: url("../../images/icons_chevron_right.svg") no-repeat center;
  opacity: 0.5;
  cursor: pointer;
}

.horizontal-table {
  border-collapse: collapse;
  width: 100%;
  border-top: 2px solid #d3d3d3; // 최상단에 연한 회색 테두리 추가

  tr {
    &:first-child {
      border-bottom: 1px solid #d3d3d3; // 맨 위 행의 가로줄을 연한 회색으로 설정합니다.
    }

    &:not(:first-child) {
      border-bottom: 1px solid #d3d3d3; // 나머지 행은 연한 회색으로 설정합니다.
    }

    &:last-child {
      // border-bottom: none; // 마지막 행의 가로줄은 제거합니다.
      border-bottom: 1px solid #d3d3d3; // 나머지 행은 연한 회색으로 설정합니다.
    }
  }

  th,
  td {
    padding: 8px;
    height: 40px;
    text-align: center; // 모든 열의 내용을 가운데 정렬합니다.
  }
  thead {
    tr {
      border-bottom: 2px solid #d3d3d3; // <thead> 아래에도 연한 회색 가로줄을 추가합니다.
      th {
        border-bottom: 1px solid #d3d3d3; // <thead>의 <th> 아래에도 선을 추가합니다.
      }
    }
  }
}
.right-text-left-arrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px; // 좌우 여백 조절

  span {
    flex: 1;
  }

  .arrow-right {
    width: 10px;
    height: 10px;
    background-color: transparent; // 화살표의 배경색 설정
  }
}

.my-page-border {
  border: 1px solid $line_light;
  height: 56px;
  border-top: 2px solid $text-primary1;
}
.my-page-border-no-line {
  height: 56px;
}

.my-page-border-top {
}
.system-red {
  color: $system_red;
}

.ask_pop {
  height: 200px;
  margin: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.04em;
  .box {
    display: flex !important;
    justify-content: center;
    // background-color: #808080;
    // color: #ffffff;
    padding: 10px; // Adjust the padding as needed
    height: 60px;
    margin-bottom: 20px;
    word-break: all;
  }
  .productName {
    width: 100%;
    display: flex !important;
    justify-content: center;
    padding: 10px;
    height: 60px;
    font-size: 1.6rem;
    margin-bottom: 20px;
  }
  .phoneNumber {
    display: flex !important;
    justify-content: center;
    height: 60px;
    font-size: 1.8rem;
    margin-bottom: 20px;
    a {
      color: red;
    }
  }
  .base-floting-btn-wrap {
    width: 100%;
    position: sticky;
    bottom: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 24px;
    padding-right: 24px;
    background-color: white;
    z-index: 10;
  }
}
.red-bubble {
  // 붉은색 배경을 설정합니다.
  background-color: #f96960;
  width: 24px;
  height: 24px;
  border-radius: 50%; // 원형 모양으로 만듭니다.

  display: flex;
  align-items: center;
  justify-content: center;
  // 버블 디자인 등의 스타일을 추가로 설정할 수 있습니다.

  // 느낌표 스타일을 설정합니다.
  &::before {
    content: "!";
    color: white; // 느낌표 색상 설정
    font-size: 16px; // 느낌표 폰트 크기 설정
    // 기타 필요한 스타일을 추가로 설정할 수 있습니다.
  }
}
.text-gray {
  color: $text-primary3;
  font-weight: 400;
  font-size: 1.4em;
}

.ask_modal {
  height: 288px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .header_section {
    display: inline-block !important;
    font-size: 1.8em;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.04em;
  }

  .content_section {
    width: 310px;
    margin: 30px; // 상하 30px 마진 추가
    display: flex;
    flex-direction: column; // 세로로 정렬하기 위해 컬럼 방향으로 설정
    align-items: center; // 수직 가운데 정렬
    justify-content: center; // 수평 가운데 정렬
    color: $text-primary3;
    // 라벨과 값 표시를 위한 스타일
    .text-line {
      display: -webkit-inline-flex !important;
      text-decoration: none !important;
      text-align: center;
      // 라벨에 대한 스타일
      .label-value {
        font-size: 1.5em;
        line-height: 22px;
        font-weight: 400;
        letter-spacing: -0.04em;
        margin-right: 10px; // 라벨과 값 간격 조절
        // 2줄로 자르기
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

  .phone_section {
    margin-left: -40px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    line-height: 26px;
    font-weight: 700;
    margin-right: 10px; // 라벨과 값 간격 조절

    &::before {
      content: url("../../images/icon_phone.svg");
      font-size: 20px;
      color: white; // 내부를 화이트로 설정
      background-color: transparent; // 배경을 투명하게 설정
      box-shadow: 0 0 0 2px white; // 화이트 테두리 추가
      border-radius: 50%; // 원형 모양으로 만듭니다.
      padding: 8px; // 내부 여백 설정
      margin-right: 10px; // 아이콘과 텍스트 간격 조절
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;

    .complete-btn {
      height: 40px;
    }
  }
}

.cancel-modal {
  min-width: 200px;
  height: auto;
  max-height: 690px;
  margin: 0 0;
  padding: 30px 0px;
  border-radius: 8px;
  background-color: #fff;

  overflow: hidden;
  position: relative;
  &__contents {
    padding-bottom: 30px;
    font-size: 1.4rem;
    text-align: center;
  }
  &__btn-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .base-btn {
    width: fit-content;
    min-width: 120px;
    height: 54px;
    font-size: 1.5rem;
    font-weight: 400;
    flex: 1;
  }
}
.s2-payment-info {
  &.info-page {
    height: 100%;
    article section.tax-calculate {
      padding: 20px 0;
      border: none;
      height: 100%;
    }
  }
  article {
    display: flex;
    flex-direction: column;
    section.tax-calculate {
      padding: 20px 20px;
      border: $line_light 1px solid;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      flex: 1;
      overflow-x: hidden;
      @include scroll-hidden;
      > div.container {
        margin-bottom: 30px;
      }
      .base-datepicker input {
        cursor: pointer;
        text-align: left !important;
        background-image: url("../../images/ic_new_calendar_b.svg");
        background-size: 22px 20px;
      }
    }

    .index-title {
      font-size: 16px;
      position: relative;
      width: fit-content;
      font-weight: 600;
      margin-bottom: 10px;
      &.required::after {
        content: "*";
        position: absolute;
        color: $system-red;
        font-size: 16px;
        right: -8px;
        top: 0;
      }
    }
    .upload-btn {
      width: 272px;
      height: 40px;
    }
    .gray-box {
      width: 100%;
      padding: 18px 20px;
      background-color: $bg-light;
      margin-bottom: 20px;
      &__line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        p:first-child {
          font-size: 14px;
          color: $text-primary2;
        }
        p:last-child {
          font-size: 15px;
          color: $text-primary1;
        }
      }
    }
  }
}
.totalPrice {
  font-weight: 600;
}
.mypage_required {
  &::after {
    content: "*";
    // position: absolute;
    margin-left: 5px;
    color: $system-red;
    font-size: 16px;
    right: -8px;
    top: 0;
  }
}
.card_payment_receipt {
  width: 343;
  height: 40;
}

.tab-radio {
  width: 100%;
  display: flex;
  padding-top: 20px;
  border-top: 1px solid $line_light;
  margin-top: 20px;
  // padding-left: 30px;

  &__wrap {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: $bg-dark;
    height: 40px;
    border-radius: 4px;
  }
  &__label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: $text-primary3;
    height: 40px;
    cursor: pointer;

    border-radius: 4px;
    &.--active {
      color: #000000;
      border: 1px solid $line_light;
      background-color: white;
    }
    &.exist-radius {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      position: relative;
      &::after {
        content: "";
        width: 100%;
        height: 3px;
        background-color: white;
        position: absolute;
        bottom: -2px;
      }
    }
  }
}
.base-btn-cashbill {
  width: fit-content;
  min-width: 100%;
  height: 40px;
  font-size: 1.5rem;
  font-weight: 400;
  flex: 1;
}
.notion-modal {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.notion-modal__content {
  flex: 1;
  position: relative;
  overflow: hidden;
}
.notion-modal__iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.radio-button-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  > div {
    flex: 0 0 calc(50% - 5px);
  }
  input[type="radio"] + label .base-radio {
    display: none;
  }
  input[type="radio"] + label {
    cursor: pointer;
    width: 100%;
    height: 40px;
    border: 1px solid $line_dark;
    border-radius: 4px;
    padding: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      font-size: 15px !important;
      font-weight: 600;
      margin: 0 !important;
    }
  }
  input[type="radio"]:checked + label {
    border: 1px solid $text-primary1;
  }
}
.cash-bill-info-page {
  margin-top: 30px;
  padding: 0 30px;
  .index-title {
    font-size: 16px;
    position: relative;
    width: fit-content;
    font-weight: 600;
    &.required::after {
      content: "*";
      position: absolute;
      color: $system-red;
      font-size: 16px;
      right: -8px;
      top: 0;
    }
  }
}
